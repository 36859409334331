<template>
  <footer class="footer">
    <a
      v-if="this.dataAgreement.legalNotice"
      @click="open('legal_notice')"
      href="javascript:void(0);"
      >{{ $t("legal_notice") }}</a
    >
    <a href="javascript:void(0);" @click="open('personal_data')">{{
      $t("personal_data")
    }}</a>
    <a
      href="javascript:void(0);"
      v-if="this.dataAgreement.termOfService"
      @click="open('term_of_service')"
      >{{ $t("terms_and_conditions") }}</a
    >
    <a
      v-if="this.design.helpContactEmail"
      :href="'mailto:' + this.design.helpContactEmail"
      >{{ $t("contact_us") }}</a
    >
    <a
      href="javascript:void(0);"
      v-if="this.design.helpContact"
      @click="open('contact_help')"
      >{{ $t("contact_help") }}</a
    >
  </footer>
  <div
    id="footer-modal"
    aria-hidden="true"
    aria-labelledby="login-modal"
    class="modal fade modal-xl"
    role="dialog"
    tabindex="-1"
    ref="modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div
          v-if="!personnalData"
          class="modal-body"
          v-html="modalContent"
        ></div>
        <div v-else class="modal-body">
          <h1>{{ $t("Personal data - Confidentiality") }}</h1>
          <p>
            {{
              $t(
                "Since May 25, 2018, the entry into force of the General Data Protection Regulation has been amended; regulations on personal data.",
              )
            }}
          </p>
          <p>
            {{
              $t(
                "We invite you to read the information notices below. The origin, purpose and processing carried out on the data collected by Studea depend, ... according to the profile of each user.",
              )
            }}
          </p>
          <p>
            {{
              $t(
                "We offer 3 notices here, depending on your user profile, we invite you to read the one that corresponds to your case",
              )
            }}:
          </p>
          <h2>
            {{
              $t(
                "You are a work-study student and you have been registered on the platform by your training organization",
              )
            }}:
          </h2>
          <a href="javascript:void(0);" @click="open('learner_privacy')"
            >> {{ $t("Personal data - Confidentiality (Alternate access)") }}</a
          >
          <h2>
            {{
              $t(
                "You are an employee of a company and you supervise a work-study program as a Tutor",
              )
            }}
          </h2>
          <a
            href="javascript:void(0);"
            @click="open('professional_tutor_privacy')"
            >>
            {{
              $t(
                "Personal data - Confidentiality (apprenticeship masters and company tutor)",
              )
            }}</a
          >
          <h2>
            {{
              $t(
                "You are an employee of a training organization, you administratively manage training courses or you supervise young people as an educational tutor",
              )
            }}
          </h2>
          <a href="javascript:void(0);" @click="open('pedagogic_tutor_privacy')"
            >>
            {{
              $t(
                "Personal data - Confidentiality (Employees of training organizations, educational tutor)",
              )
            }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { Modal } from "bootstrap";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "FooterComponent",
  data() {
    return {
      modalTitle: "",
      modalContent: "",
      personnalData: false,
      modalOpenened: false,
    };
  },
  mounted() {
    this.$refs.modal.addEventListener("hidden.bs.modal", this.hidden);
  },
  computed: {
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
      dataAgreement: (store) => store.dataAgreement,
    }),
  },
  methods: {
    open(type) {
      this.personnalData = false;
      switch (type) {
        case "presentation":
          this.modalTitle = this.$t("login_presentation");
          this.modalContent = this.design.presentation;
          break;
        case "legal_notice":
          this.modalTitle = this.$t("legal_notice");
          this.modalContent = this.dataAgreement.legalNotice;
          break;
        case "term_of_service":
          this.modalTitle = this.$t("terms_and_conditions");
          this.modalContent = this.dataAgreement.termOfService;
          break;
        case "personal_data":
          this.modalTitle = this.$t("personal_data");
          this.personnalData = true;
          break;
        case "learner_privacy":
          this.modalTitle = this.$t("learner_privacy");
          this.modalContent = this.dataAgreement.learnerPrivacy;
          break;
        case "professional_tutor_privacy":
          this.modalTitle = this.$t("professional_tutor_privacy");
          this.modalContent = this.dataAgreement.professionalTutorPrivacy;
          break;
        case "pedagogic_tutor_privacy":
          this.modalTitle = this.$t("pedagogic_tutor_privacy");
          this.modalContent = this.dataAgreement.pedagogicTutorPrivacy;
          break;
        case "contact_help":
          this.modalTitle = this.$t("contact_help");
          this.modalContent = this.design.helpContact;
          break;
      }
      if (!this.modalOpenened) {
        const container = this.$refs.modal;
        const modal = new Modal(container);
        modal.show();
        this.modalOpenened = true;
      }
    },

    hidden() {
      this.modalOpenened = false;
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  z-index: 1049;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  height: 25px;
  display: flex;
  justify-content: center;
  box-shadow: 0 -20px 20px rgba(126, 142, 177, 0.12);

  a {
    margin: 0 25px;
    color: $dark-grey;
  }

  @include up-to-lg {
    display: none;
  }
}

#login-modal,
.modal-header,
.modal-body,
.modal-content {
  color: var(--primary-color) !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--primary-color) !important;
  }
}

.modal-body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 15px 0 25px 0;
  }
}
</style>
