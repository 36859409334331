import { defineStore } from "pinia";

export const useChatbotStore = defineStore({
  id: "Chatbot",
  state: () => {
    return {
      messages: [
        {
          content: "Bonjour, en quoi puis-je vous aider ?",
          writer: "chatbot",
        },
      ],
      loading: false,
    };
  },

  actions: {
    storeMessage(message) {
      this.messages.push(message);
    },
    chatbotRequest() {
      this.loading = true;
    },
    chatbotSuccess() {
      this.loading = false;
    },
  },
});
