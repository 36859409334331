<template>
  <div class="container-fluid">
    <div class="row">
      <NewsModalComponent
        :opened="isModalOpened"
        :news="newsToDisplay"
        :picture="modalImage"
        @hidden="closeModal"
      />
      <div class="col-lg-3 order-1 order-lg-0">
        <TriadMemberBlock />
        <ContractBlock />
        <DocumentsBlock />
      </div>
      <div class="col-lg-6 order-0 order-lg-1">
        <QuestionnaireToDoBlockComponent
          v-if="toComplete.length || toSign.length"
        />
        <QuestionnaireListBlockComponent
          v-for="year in questionnaires"
          :key="year.id"
          :accordion="true"
          :year="year"
        />
        <div class="d-flex justify-content-end p-3 gap-3">
          <button
            class="btn btnStyle btn-success"
            @click="downloadFileBooklet()"
          >
            <FontAwesomeIcon icon="fa-light fa-arrow-down-to-bracket" />
            {{ $t("dashboard.Download_the_booklet") }}
          </button>
          <button
            class="btn btnStyle btn-success"
            @click="downloadFileKeyDates()"
          >
            <FontAwesomeIcon icon="fa-light fa-arrow-down-to-bracket" />
            {{ $t("dashboard.Download_key_dates") }}
          </button>
        </div>
      </div>
      <div class="col-lg-3 order-2">
        <ProgressBarBlock :label="$t('yearProgression')" type="purcent" />
        <TimelineBlock />
        <NewsBlockComponent
          v-if="news && news.length"
          :loading="loading"
          :news="news"
        />
      </div>
    </div>
  </div>
  <InfosComponent />
</template>

<script>
import { mapState } from "pinia";
import TriadMemberBlock from "@/components/blocks/TriadMemberBlock.vue";
import TimelineBlock from "@/components/blocks/TimelineBlock.vue";
import ContractBlock from "@/components/blocks/ContractBlock.vue";
import InfosComponent from "@/components/InfosComponent.vue";
import ProgressBarBlock from "@/components/blocks/ProgressBarBlock.vue";
import DocumentsBlock from "@/components/blocks/DocumentsBlock.vue";
import QuestionnaireToDoBlockComponent from "@/components/questionnaire/QuestionnaireToDoBlockComponent.vue";
import QuestionnaireListBlockComponent from "@/components/questionnaire/QuestionnaireListBlockComponent.vue";
import { triadManager } from "@/services/triad/triad-manager";
import NewsBlockComponent from "@/components/blocks/NewsBlockComponent.vue";
import NewsModalComponent from "@/components/NewsModalComponent.vue";
import { pictureManager } from "@/services/utilities/picture-manager";
import { apiConnection } from "@/services/api-connection";
import { useTriadStore } from "@/store/triad/triad";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";
import { useUserStore } from "@/store/user/user";
import { useTrainingCourseStore } from "@/store/training-course/training-course";

export default {
  name: "DashboardView",
  components: {
    NewsModalComponent,
    NewsBlockComponent,
    QuestionnaireListBlockComponent,
    QuestionnaireToDoBlockComponent,
    DocumentsBlock,
    ProgressBarBlock,
    ContractBlock,
    TimelineBlock,
    TriadMemberBlock,
    InfosComponent,
  },
  data() {
    return {
      news: [],
      isModalOpened: false,
      newsToDisplay: null,
      modalImage: null,
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useQuestionnaireStore, {
      questionnaires: (store) => store.questionnaires,
      toSign: (store) => store.toSign,
      toComplete: (store) => store.toComplete,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
  },
  mounted() {
    this.news = this.triad.news;
    this.setNewsToDisplay();
  },
  watch: {
    triad() {
      this.news = this.triad.news;
      this.setNewsToDisplay();
    },

    news() {
      this.setNewsToDisplay();
    },
  },
  methods: {
    downloadFileBooklet() {
      triadManager.pdf(this.triad.id);
    },

    downloadFileKeyDates() {
      console.log("Download file key dates");
    },

    isNewsPoping() {
      if (this.triad && this.triad.news && this.triad.news.length) {
        if (this.newsToDisplay) {
          this.modalImage = this.setNewsModalPicture(
            this.newsToDisplay.picture,
          );
          this.isModalOpened = true;
        }
      }
    },

    setNewsToDisplay() {
      if (this.news && this.news.length) {
        this.newsToDisplay = this.news.find((element) => element.popup);
      }
      if (!this.isModalOpened) {
        this.isNewsPoping();
      }
    },

    setNewsModalPicture(picture) {
      return (this.picture = pictureManager.setPicture(picture));
    },

    closeModal() {
      if (this.newsToDisplay && this.newsToDisplay.id) {
        apiConnection.put("/app/news/user-has-seen/" + this.newsToDisplay.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  color: $purple;
}

.svg-inline--fa {
  margin-right: 8px;
}
</style>
