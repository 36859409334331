<template>
  <form class="form needs-validation" novalidate="novalidate">
    <div class="grid">
      <div class="row w-100">
        <template v-for="model in formModels" :key="model.vars.id">
          <div v-if="model.vars.line_break" class="col-12"></div>
          <div
            v-if="
              (!model.vars.attr || !model.vars.attr['data-form-sticky']) &&
              !model.vars.hide
            "
            :class="
              'form-group ' +
              (model.vars.row_attr && model.vars.row_attr.class
                ? ' ' + model.vars.row_attr.class
                : '') +
              (model.vars.toggle_class
                ? ' d-none ' + model.vars.toggle_class
                : '')
            "
          >
            <ModelComponent
              :disableFocusAnimation="disableFocusAnimation"
              :model="model"
              @ajaxTargetLoad="ajaxTargetLoad"
              @ajaxTargetTrig="ajaxTargetTrig"
              @update="update"
              @toggle="toggle"
              @input="input"
              @uploading="uploading"
              @uploaded="uploaded"
              @dateChanged="dateChanged"
            />
          </div>
        </template>
      </div>
      <div v-if="stickyFields.length" class="stickyForm">
        <div class="stickyForm__content">
          <template v-for="model in stickyFields" :key="model.vars.id">
            <div
              v-if="model.vars.attr && model.vars.attr['data-form-sticky']"
              :class="
                'form-group ' +
                (model.vars.row_attr && model.vars.row_attr.class
                  ? ' ' + model.vars.row_attr.class
                  : '') +
                (model.vars.toggle_class
                  ? ' d-none ' + model.vars.toggle_class
                  : '')
              "
            >
              <ModelComponent
                :disableFocusAnimation="disableFocusAnimation"
                :model="model"
                @ajaxTargetLoad="ajaxTargetLoad"
                @ajaxTargetTrig="ajaxTargetTrig"
                @update="update"
                @toggle="toggle"
                @uploading="uploading"
                @uploaded="uploaded"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <SubmitTypeComponent
      v-if="
        (!submitModel || (submitModel && submitModel.vars.display)) &&
        !hideSubmit
      "
      :defaultColors="defaultColors"
      :model="submitModel"
      @cancelForm="cancel()"
      @submitForm="submit()"
    />
  </form>
</template>

<script>
import SubmitTypeComponent from "@/components/form/SubmitTypeComponent.vue";
import { formValidator } from "@/services/form/form-validator";
import ModelComponent from "@/components/form/ModelComponent.vue";
import { formManager } from "@/services/form/form-manager";

export default {
  name: "FormComponent",
  components: {
    ModelComponent,
    SubmitTypeComponent,
  },
  data() {
    return {
      errors: [],
      formModels: this.models,
      submitModel: null,
      stickyFields: [],
      fileUploading: false,
      labelChoices: {
        vars: {
          block_prefixes: ["", "choice"],
          label: false,
          expanded: false,
          multiple: true,
          name: "labelChoices",
          value: "",
          required: true,
          id: "labelChoices",
          choices: this.labelChoices,
        },
      },
    };
  },
  props: {
    models: {
      type: [],
      required: true,
      default: [],
    },
    disableFocusAnimation: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultColors: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    keepHideValue: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  updated() {
    this.retrieveSubmitModel();
  },
  created() {
    this.retrieveSubmitModel();
  },
  mounted() {
    this.isStickyBlock();
    Object.values(this.models).forEach(
      function (model) {
        this.checkToggleTarget(model);
        this.dateChanged(model);
      }.bind(this),
    );
  },
  methods: {
    submit() {
      this.errors = formValidator.validForm(this.formModels);
      if (!this.errors.length && !this.fileUploading) {
        console.log("validation OK !");
        this.$emit("formSubmit", this.formModels);
      }
    },
    cancel() {
      this.$emit("formCancel");
    },
    retrieveSubmitModel() {
      let response = null;
      Object.values(this.models).forEach(function (model) {
        if (formManager.isSubmitType(model)) {
          response = model;
        }
      });
      this.submitModel = response;
    },
    update() {
      this.$emit("update");
    },
    input(value) {
      this.$emit("input", value);
    },
    toggle(model) {
      this.$emit("toggle", model);
      this.checkToggleTarget(model);
    },

    uploading() {
      this.fileUploading = true;
    },

    uploaded(data) {
      this.fileUploading = false;
      this.$emit("uploaded", data);
    },

    dateChanged(model) {
      Object.values(this.models).forEach((item) => {
        if (item.children) {
          if (
            !Object.keys(item.children).length ||
            formManager.isDateType(item)
          ) {
            if (
              item.vars.attr &&
              (item.vars.attr["data-day-ref"] ||
                item.vars.attr["data-hour-ref"] ||
                item.vars.attr["data-datehour-ref"])
            ) {
              this.addDateRules(item, model);
            }
          } else {
            Object.values(item.children).forEach((child) => {
              if (
                child.vars.attr &&
                (child.vars.attr["data-day-ref"] ||
                  child.vars.attr["data-hour-ref"] ||
                  item.vars.attr["data-datehour-ref"])
              ) {
                this.addDateRules(child, model);
              }
            });
          }
        }
      });
    },

    addDateRules(model, ref) {
      let minTime = null;
      if (ref.vars.value) {
        if (model.vars.attr["data-day-ref"] === ref.vars.name) {
          minTime = ref.vars.value.split(" ")[0];
        } else if (model.vars.attr["data-hour-ref"] === ref.vars.name) {
          if (formManager.isTimeType(ref)) {
            minTime = ref.vars.value;
          } else {
            minTime = ref.vars.value.split(" ")[1].slice(0, -3);
          }
        } else if (model.vars.attr["data-datehour-ref"] === ref.vars.name) {
          minTime = ref.vars.value;
        }

        if (minTime) {
          model.vars.attr["data-min-date"] = minTime;
          if (minTime > model.vars.value) {
            model.vars.value = minTime;
          }
        }
      }
    },

    checkToggleTarget(model) {
      if (model.vars.toggler_target) {
        const value = model.vars.value;
        const targetClass = model.vars.toggler_target + "-" + value;

        let fieldsToHide = document.querySelectorAll(
          '[class*="' + model.vars.toggler_target + '"]',
        );
        let fieldsToShow = document.getElementsByClassName(targetClass);

        for (let i = 0; i < fieldsToHide.length; i++) {
          fieldsToHide[i].classList.add("d-none");
        }
        for (let i = 0; i < fieldsToShow.length; i++) {
          fieldsToShow[i].classList.remove("d-none");
        }

        fieldsToShow = Object.values(this.models).filter(
          (item) =>
            item.vars.toggle_class &&
            item.vars.toggle_class.includes(
              model.vars.toggler_target + "-" + model.vars.value,
            ),
        );

        fieldsToShow.forEach((field) => {
          field.vars.ignoreValidation = false;
        });

        fieldsToHide = Object.values(this.models).filter(
          (item) =>
            item.vars.toggle_class &&
            item.vars.toggle_class.includes(model.vars.toggler_target) &&
            !item.vars.toggle_class.includes(
              model.vars.toggler_target + "-" + model.vars.value,
            ),
        );
        fieldsToHide.forEach((field) => {
          if (!this.keepHideValue) {
            field.vars.value =
              typeof field.vars.value === "boolean" ? false : null;
          }
          field.vars.ignoreValidation = true;
        });
      }
    },
    ajaxTargetLoad(payload) {
      if (this.models[payload.target]) {
        this.formModels[payload.target].vars.choices = payload.choices;
        this.formModels[payload.target].vars.value = Object.values(
          payload.choices,
        ).find(
          (choice) =>
            choice.value.toString() ===
            this.formModels[payload.target].vars.value,
        );
      }
    },

    isStickyBlock() {
      this.stickyFields = Object.values(this.models).filter(
        (model) => model.vars.attr && model.vars.attr["data-form-sticky"],
      );
    },

    ajaxTargetTrig(payload) {
      formManager.fieldLoad(this.models[payload.target]);
    },
  },
};
</script>

<style lang="scss" scoped>
.non-empty {
  overflow: auto;
}

.grid {
  display: flex;

  .row {
    flex: 1;
  }
}

.stickyForm {
  min-width: 500px;
  flex: 0;

  &__content {
    z-index: 10000;
    border: 1px dashed $m-color_4;
    margin-left: 45px;
    padding: 20px;
    height: auto;
    border-radius: 6px;
    position: sticky;
    top: 135px;
  }
}
</style>
