<template>
  <div class="container-fluid">
    <SideMenuComponent :admin="true" :menu="menu" />
    <div class="content-wrapper">
      <ErrorView v-if="errorStatus" />
      <router-view v-else></router-view>
    </div>
  </div>
</template>

<script>
import SideMenuComponent from "@/components/SideMenuComponent.vue";
import { mapActions, mapState } from "pinia";
import ErrorView from "@/views/Error/ErrorView.vue";
import { useAdminStore } from "@/store/admin/admin";
import { useErrorStore } from "@/store/error/error";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "LayoutView",
  data() {
    return {};
  },
  beforeRouteUpdate() {
    this.getMenu();
  },
  created() {
    this.getMenu(true);
  },
  mounted() {
    if (this.needUpdate) {
      this.getConfig();
    }
  },
  updated() {
    if (this.needUpdate) {
      this.getConfig();
    }
  },
  computed: {
    ...mapState(useErrorStore, {
      errorStatus: (store) => store.status,
    }),
    ...mapState(usePlatformStore, {
      needUpdate: (store) => store.needUpdate,
    }),
    ...mapState(useAdminStore, {
      menu: (store) => store.menu,
    }),
  },
  methods: {
    getMenu(init = false) {
      this.fetchMenu(init);
    },

    getConfig() {
      this.fetchConfig();
      this.setNeedUpdate(false);
    },

    ...mapActions(useAdminStore, ["fetchMenu"]),
    ...mapActions(usePlatformStore, ["setNeedUpdate", "fetchConfig"]),
  },
  components: { ErrorView, SideMenuComponent },
};
</script>

<style lang="scss" scoped></style>
