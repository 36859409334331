import { apiConnection } from "@/services/api-connection";
import router from "@/router";
import { mainStore } from "@/main";

const URL_INFOS = "/app/user/me";
const URL_ONE_USER = "/app/user/info/";
const URL_USER = "/ufauser/";
const URL_CHECK = "check_username";
const URL_POST = "/app/user/put/";
const URL_POST_PICTURE = "/post-picture";
const URL_PERMISSIONS_ADMIN = "/app/user/permissions";
const URL_PERMISSIONS_STUDEA_MANAGER = "/app/studea-manager/permissions/";

class UserManager {
  PROFILE_APPRENTICE = 1;
  PROFILE_TUTOR = 2;
  PROFILE_APPRENTICE_MASTER = 3;
  PROFILE_STUDEA_MANAGER = 4;

  loadUserInfos() {
    return apiConnection.get(URL_INFOS, {}, true).then((response) => response);
  }

  loadUser(id) {
    return apiConnection.get(URL_ONE_USER + id).then((response) => response);
  }

  loadPermissions(trainingCourse = null) {
    if (trainingCourse) {
      return apiConnection
        .get(URL_PERMISSIONS_STUDEA_MANAGER + trainingCourse)
        .then((response) => response);
    } else {
      return apiConnection
        .get(URL_PERMISSIONS_ADMIN)
        .then((response) => response);
    }
  }

  checkUsername = (username) => {
    return apiConnection
      .get(
        URL_USER + URL_CHECK,
        {
          username: username,
        },
        false,
      )
      .then((response) => response);
  };

  logout() {
    window.localStorage.removeItem("studea-refreshToken");
    window.sessionStorage.removeItem("studea-refreshToken");
    window.localStorage.removeItem("studea-returnPathName");
    window.localStorage.removeItem("studea-pageTitle");
    window.localStorage.removeItem("studea-token");
    window.sessionStorage.removeItem("studea-token");
    window.sessionStorage.removeItem("studea-switched");
    window.localStorage.removeItem("studea-stayConnected");
    window.localStorage.removeItem("studea-oldTriad");
    window.localStorage.removeItem("studea-oldTrainingCourse");
    mainStore.setAppLoading(true);
    mainStore.initStore();
    setTimeout(function () {
      router.push({ name: "Login" });
      mainStore.setAppLoading(false);
    }, 300);
  }

  putUserInfos(id, datas) {
    return apiConnection.put(URL_POST + id, datas).then((response) => response);
  }

  postUserPicture(data, folder) {
    return apiConnection
      .post(
        URL_POST_PICTURE,
        {
          data: data.data,
          filename: data.fileName,
          type: data.type,
          folder: folder,
        },
        true,
      )
      .then((response) => response);
  }

  getProfileKey(profile) {
    let key;
    switch (profile) {
      case this.PROFILE_APPRENTICE:
        key = "apprentice";
        break;
      case this.PROFILE_TUTOR:
        key = "tutor";
        break;
      case this.PROFILE_APPRENTICE_MASTER:
        key = "apprenticeMaster";
        break;
      case this.PROFILE_STUDEA_MANAGER:
        key = "studeaManager";
        break;
    }
    return key;
  }

  getProfileByName(profileName) {
    let key;
    switch (profileName) {
      case "apprentices":
        key = this.PROFILE_APPRENTICE;
        break;
      case "tutors":
        key = this.PROFILE_TUTOR;
        break;
      case "apprenticeMasters":
        key = this.PROFILE_APPRENTICE_MASTER;
        break;
      case "studeaManagers":
        key = this.PROFILE_STUDEA_MANAGER;
        break;
    }
    return key;
  }

  checkUserProfile(user, triad) {
    if (triad.apprentice && user.id === triad.apprentice.id) {
      return this.PROFILE_APPRENTICE;
    } else if (triad.tutor && user.id === triad.tutor.id) {
      return this.PROFILE_TUTOR;
    } else if (
      triad.apprenticeMaster &&
      user.id === triad.apprenticeMaster.id
    ) {
      return this.PROFILE_APPRENTICE_MASTER;
    } else {
      return this.PROFILE_STUDEA_MANAGER;
    }
  }

  findUserType(userType, triad) {
    if (triad.triadUsers) {
      return triad.triadUsers.filter(
        (triadUser) => triadUser.userType === userType,
      );
    }
  }
}

export const userManager = new UserManager();
