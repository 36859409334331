<template>
  <div class="position-relative vh-50">
    <QuestionnaireModalComponent
      :item="modalItem"
      :parent="modalParent"
      :type="modalType"
      @hidden="closeSettings"
      @update="update"
    />
    <QuestionnaireConfigComponent
      :code="settingsCode"
      :opened="settingsOpened"
      :config="config"
      @hidden="this.settingsOpened = false"
      @opening="toggleEditTitle"
      @update="update"
    />
    <QuestionnaireToolBarComponent
      @add="add"
      @click="stop($event)"
      @save="save"
      @settings="globalSettings"
    />
    <div id="editor" ref="questionnaire" class="editor">
      <QuestionnaireHeadingComponent
        :editDescription="editDescription"
        :editTitle="editTitle"
        :saved="saved"
        :pdfSrc="pdfSrc"
        @toggleDescription="toggleEditDescription"
        @toggleTitle="toggleEditTitle"
        @update="update"
      />
      <QuestionnaireQuestionComponent
        v-if="questionnaire.useInterviewDate"
        :key="key"
        :index="50000"
        :item="interviewDate"
        :notSortable="true"
      />
      <VueDraggableNext
        :animation="200"
        :delay="200"
        :delay-on-touch-only="true"
        :list="questionnaire.questions"
        handle=".drag"
        @click="toggleEditTitle(false, $event)"
        @update="update"
      >
        <div v-for="(item, index) in questionnaire.questions" :key="item.id">
          <QuestionnaireQuestionComponent
            v-if="item.itemType === 'field'"
            :index="index"
            :item="item"
            @duplicate="duplicate"
            @remove="remove"
            @rights="rights"
            @selectBlock="selectBlock"
            @settings="settings"
            @skills="skills"
            @update="update"
          />
          <QuestionnaireClusterComponent
            v-else
            :index="index"
            :item="item"
            @duplicate="duplicate"
            @remove="remove"
            @selectBlock="selectBlock"
            @settings="settings"
            @rights="rights"
            @skills="skills"
            @update="update"
          />
        </div>
      </VueDraggableNext>
      <div
        v-if="
          questionnaire.questions &&
          !Object.keys(questionnaire.questions).length
        "
        class="widget"
        @click="stop($event)"
      >
        <div
          class="widget-heading justify-content-center align-items-center flex-column"
        >
          <div class="h6 textStyle">
            {{ $t("studea_manager.questionnaire.empty_title") }}
          </div>
          <div class="mt-2">
            <button
              class="btn btn-secondary mx-1"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.add_title'),
                position: 'left',
              }"
              @click="add(2)"
            >
              <FontAwesomeIcon icon="fa-light fa-text" />
            </button>
            <button
              class="btn btn-secondary mx-1"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.add_question'),
                position: 'left',
              }"
              @click="add(1)"
            >
              <FontAwesomeIcon icon="fa-light fa-plus" />
            </button>
            <button
              class="btn btn-secondary mx-1"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.add_question_cluster'),
                position: 'left',
              }"
              @click="add(3)"
            >
              <FontAwesomeIcon icon="fa-light fa-diagram-cells" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading" position="fixed" />
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
import { mapActions, mapState } from "pinia";
import { notificationManager } from "@/services/utilities/notification-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import QuestionnaireQuestionComponent from "@/components/questionnaire/editor/QuestionnaireQuestionComponent.vue";
import QuestionnaireClusterComponent from "@/components/questionnaire/editor/QuestionnaireClusterComponent.vue";
import QuestionnaireHeadingComponent from "@/components/questionnaire/editor/QuestionnaireHeadingComponent.vue";
import QuestionnaireToolBarComponent from "@/components/questionnaire/editor/QuestionnaireToolBarComponent.vue";
import QuestionnaireConfigComponent from "@/components/questionnaire/editor/QuestionnaireConfigComponent.vue";
import QuestionnaireModalComponent from "@/components/questionnaire/editor/QuestionnaireModalComponent.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { sortManager } from "@/services/utilities/sort-manager";
import { useStudeaManagerQuestionnaireStore } from "@/store/studea_manager/studea-manager-questionnaire";
import { useTriadStore } from "@/store/triad/triad";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "QuestionnaireAddEditView",
  components: {
    QuestionnaireModalComponent,
    QuestionnaireConfigComponent,
    QuestionnaireToolBarComponent,
    QuestionnaireHeadingComponent,
    QuestionnaireClusterComponent,
    QuestionnaireQuestionComponent,
    BlockLoader,
    VueDraggableNext,
  },
  data() {
    return {
      modalItem: [],
      modalParent: [],
      settingsCode: "",
      settingsOpened: false,
      editTitle: false,
      editDescription: false,
      saved: true,
      loading: false,
      modalType: null,
      selected: null,
      config: [],
      key: 0,
      pdfSrc: "",
      interviewDate: {
        id: null,
        index: 50000,
        title:
          this.questionnaire && this.questionnaire.interviewDateLabel
            ? this.questionnaire.interviewDateLabel
            : "",
        type: 11,
        itemType: "field",
        access: [
          {
            label: "Alternant",
            value: "1",
            data: 1,
            checked: false,
          },
          {
            label: "Tuteur pédagogique",
            value: "2",
            data: 2,
            checked: false,
          },
          {
            label: "Tuteur entreprise",
            value: "3",
            data: 3,
            checked: false,
          },
        ],
        tip: "",
        tipActive: false,
        required: 1,
        selected: false,
        displayMode: 1,
        comments: false,
        commentsLabel: "",
        commentsType: 1,
        displayMark: false,
        displayRadar: false,
        maximalMark: "",
        openedQuestionsAccess: [],
        openedQuestions: false,
        openedQuestionsType: 1,
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    this.clearEvents();
    if (!this.saved) {
      next(false);
      this.alertNotSaved(to);
    } else {
      next();
    }
  },
  created() {
    this.initEvents();
    this.initConfigForm();
    if (this.$route.params.id) {
      this.initEdition();
    } else {
      this.retrieveNew();
      if (!this.questionnaire || !Object.keys(this.questionnaire).length) {
        this.$router.push({ name: "studeaManagerQuestionnaireList" });
      } else if (this.questionnaire && this.questionnaire.questions) {
        this.initLastIndex(Object.keys(this.questionnaire.questions).length);
        this.saved = false;
      }
    }

    this.interviewDate.title = this.questionnaire.interviewDateLabel;
    this.key++;
  },
  mounted() {
    if (
      this.apprenticeProfileLabel &&
      this.tutorProfileLabel.title &&
      this.apprenticeMasterProfileLabel.title
    ) {
      this.initInterviewDateAccess();
    }
  },
  watch: {
    triad() {
      if (this.$route.params.id) {
        this.initEdition();
      }
    },
    "questionnaire.interviewDateLabel"() {
      this.interviewDate.title = this.questionnaire.interviewDateLabel;
      this.key++;
    },
    apprenticeProfileLabel() {
      this.initInterviewDateAccess();
    },
    tutorProfileLabel() {
      this.initInterviewDateAccess();
    },
    apprenticeMasterProfileLabel() {
      this.initInterviewDateAccess();
    },
  },
  computed: {
    ...mapState(useStudeaManagerQuestionnaireStore, {
      questionnaire: (store) => store.questionnaire,
      lastSavedVersion: (store) => store.lastSavedVersion,
      lastIndex: (store) => store.lastIndex,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
      tutorProfileLabel: (store) => store.tutorProfileLabel,
      apprenticeMasterProfileLabel: (store) =>
        store.apprenticeMasterProfileLabel,
    }),
  },
  methods: {
    initInterviewDateAccess() {
      this.interviewDate.access = [
        {
          label: this.apprenticeProfileLabel.title,
          value: "1",
          data: 1,
          checked: false,
        },
        {
          label: this.tutorProfileLabel.title,
          value: "2",
          data: 2,
          checked: false,
        },
        {
          label: this.apprenticeMasterProfileLabel.title,
          value: "3",
          data: 3,
          checked: false,
        },
      ];
    },

    clearEvents() {
      const container = document.body;
      if (container) {
        container.removeEventListener("click", this.initClick);
      }
    },

    initEdition(refresh = false, id = null) {
      if (!refresh) {
        this.questionnaireUpdate({ questionnaire: [] });
      }
      this.loading = true;
      this.fetchEditorQuestionnaire({
        id: id ? id : this.$route.params.id,
        trainingCourseId: this.$route.params.trainingCourseId,
      }).then(() => {
        this.initLastIndex(0);
        this.setPdfSrc();
        if (this.questionnaire.questions) {
          Object.values(this.questionnaire.questions).forEach((field) => {
            this.initLastIndex(this.lastIndex + 1);
            field["index"] = this.lastIndex;
          });
        }
        this.initQuestionnaireChoices();
        if (refresh) {
          this.selectBlock(this.selected);
        }
      });
    },

    initEvents() {
      const container = document.body;
      if (container) {
        container.addEventListener("click", this.initClick);
      }
    },

    initClick() {
      this.selectBlock(null);
    },

    selectBlock(index) {
      this.questionnaireSelectBlock(index);
      this.editTitle = false;
      this.selected = index;
    },

    toggleEditTitle(value, e) {
      if (e) {
        e.stopPropagation();
      }
      this.editTitle = value;
    },

    toggleEditDescription(value, e) {
      if (e) {
        e.stopPropagation();
      }
      this.editDescription = value;
    },

    setPdfSrc() {
      if (this.questionnaire.base64Pdf) {
        this.pdfSrc = this.questionnaire.base64Pdf;
      }
    },

    remove(index) {
      this.saved = false;
      this.questionnaireRemove(index);
      const prev = this.questionnaire[index - 1];
      if (prev) {
        this.selectBlock(prev.id);
      } else {
        const next = this.questionnaire[index + 1];
        if (next) {
          this.selectBlock(next.id);
        }
      }
    },

    update() {
      this.questionnaireUpdate({
        questionnaire: this.questionnaire,
        save: !this.$route.params.id,
      });
      this.setPdfSrc();
      this.checkChanges();
    },

    checkChanges() {
      let processed = JSON.parse(JSON.stringify(this.questionnaire));
      processed = questionnaireManager.processQuestionnaire(processed);
      const last = JSON.parse(this.lastSavedVersion);
      let needSave = false;

      Object.entries(processed).forEach((entries) => {
        const key = entries[0];
        let value = entries[1];

        if (["triads", "crews"].includes(key)) {
          value = sortManager.sort(value, "id", "ASC");
        }

        if (JSON.stringify(value) !== JSON.stringify(last[key])) {
          needSave = true;
        }
      });

      this.saved = !needSave;
    },

    save(to = null, refresh = true) {
      const valid = this.validQuestions();
      this.update();
      let id = this.$route.params.id;
      if (valid) {
        this.saved = true;
        this.loading = true;
        this.saveQuestionnaire({
          trainingCourseId: this.$route.params.trainingCourseId,
          id: this.$route.params.id,
        }).then((res) => {
          if (
            !this.$route.params.id &&
            res.responses[0] &&
            res.responses[0].object.id
          ) {
            id = res.responses[0].object.id;
            to = {
              name: "studeaManagerQuestionnaireEdit",
              params: {
                id: id,
              },
            };
          }

          if (to) {
            this.$router.push(to);
          }
          if (refresh) {
            this.initEdition(true, id);
          }
          this.loading = false;
        });
      } else {
        notificationManager.showNotification(
          "error",
          this.$t("studea_manager.questionnaire.missing_error"),
        );
      }
    },

    validQuestions() {
      const questions = this.questionnaire.questions;
      let valid = true;
      let selected = false;
      if (Object.keys(questions).length) {
        Object.values(questions).forEach((question) => {
          valid = questionnaireManager.validQuestion(question, valid);

          if (!selected && !valid) {
            question.selected = true;
            selected = true;
          }
        });
      }
      return valid;
    },

    stop(e) {
      e.stopPropagation();
    },

    duplicate(payload) {
      this.saved = false;
      this.questionnaireDuplicate(payload);
      this.selectBlock(this.lastIndex);
    },

    add(type) {
      this.saved = false;
      this.questionnaireAdd(type);
      this.selectBlock(this.lastIndex);
      this.scrollToBottom();
    },

    settings(payload) {
      this.modalType = null;
      this.modalItem = payload.item;
    },

    rights(payload) {
      this.modalType = "rights";
      this.modalItem = payload.item;
      this.modalParent = payload.parent;
    },

    skills(payload) {
      this.modalType = "skills";
      this.modalItem = payload.item;
    },

    globalSettings(code) {
      this.settingsCode = code;
      this.settingsOpened = true;
    },

    closeSettings() {
      this.modalItem = [];
      this.modalType = null;
    },

    scrollToBottom() {
      const $this = this;
      setTimeout(function () {
        window.scrollTo({
          behavior: "smooth",
          top: $this.$refs.questionnaire.getBoundingClientRect().bottom,
        });
      }, 50);
    },

    alertNotSaved(to) {
      return notificationManager
        .showAlert(
          "warning",
          "Attention",
          "Êtes-vous sûr de vouloir fermer sans sauvegarder ?",
          true,
          true,
          "Sauvegarder",
          "Quitter sans sauvegarder",
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.save(to, false);
          } else if (res.isDismissed && res.dismiss === "cancel") {
            window.localStorage.removeItem("studea-questionnaireEditor");
            this.saved = true;
            this.$router.push(to);
          }
        });
    },

    initConfigForm() {
      this.loading = true;
      studeaManagerManager
        .getQuestionnaireConfig(
          this.$route.params.trainingCourseId,
          this.$route.params.id,
        )
        .then((data) => {
          this.config = data.tabs;
          this.loading = false;
        });
    },

    ...mapActions(useStudeaManagerQuestionnaireStore, [
      "retrieveNew",
      "initLastIndex",
      "questionnaireUpdate",
      "fetchEditorQuestionnaire",
      "initQuestionnaireChoices",
      "questionnaireSelectBlock",
      "questionnaireRemove",
      "questionnaireUpdate",
      "questionnaireDuplicate",
      "questionnaireAdd",
      "saveQuestionnaire",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.editor {
  padding-right: 80px;

  @include up-to-md {
    padding-right: 0;
    padding-bottom: 60px;
  }
}

.vh-50 {
  min-height: 80vh;
}
</style>
