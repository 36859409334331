<template>
  <div
    :class="{ backdrop: true, '-on': appLoading, '-on -file': fileDownloading }"
  >
    <div :class="'loader-app ' + size">
      <div class="purple1 -fade--1"></div>
      <div class="purple2 -fade--2"></div>
      <div class="green -fade--3"></div>
      <div class="red -fade--4"></div>
      <div class="text textStyle" v-if="fileDownloading">
        {{ $t("file_downloading") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main/main";

export default {
  name: "LoaderComponent",
  props: {
    size: {
      type: String,
      required: false,
      default: "small",
    },
  },
  computed: {
    ...mapState(useMainStore, {
      appLoading: (store) => store.appLoading,
      fileDownloading: (store) => store.fileDownloading,
    }),
  },
};
</script>

<style lang="scss" scoped>
.backdrop {
  opacity: 0;
  visibility: hidden;
  @include pseudo-pos;
  @include transition-all;
  z-index: 10000;
  height: 100vh;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    opacity: 0;
  }

  @include dark-theme {
    background: $m-color_10;
  }

  &.-on {
    visibility: visible;
    opacity: 1;

    &:after {
      opacity: 1;
    }

    .loader-app {
      visibility: visible;
      opacity: 1;
    }
  }

  &.-file {
    opacity: 1;

    &:after {
      opacity: 0.5;
    }

    .loader-app {
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -36%);
        width: 250px;
        height: 150px;
        background: $white;
        border-radius: 6px;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }
}

img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -47%);
}

.loader-app {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  @include transition-all;
  z-index: 10001;

  &.small {
    width: 75px;
    height: 75px;
  }

  &.medium {
    width: 100px;
    height: 100px;
  }

  &.large {
    width: 175px;
    height: 175px;
  }

  .purple1,
  .purple2,
  .green,
  .red {
    position: absolute;
    height: 16%;
    border-radius: 100px;
    opacity: 1;

    @for $i from 1 through 4 {
      &.-fade--#{$i} {
        animation: fadeInOut 1000ms ease-in-out infinite;
        animation-delay: 200ms * $i * 1;
      }
    }
  }

  .purple1,
  .purple2 {
    background: $purple;
    width: 100%;
    transform: rotate(-60.7deg);
    z-index: 1;
    bottom: 0;
    left: 0;
    transform-origin: 9% 50%;
  }

  .purple2 {
    z-index: 1;
    transform: rotate(60.4deg);
    right: 0;
    left: auto;
    transform-origin: 91% 50%;
  }

  .green {
    background: $green;
    transform: rotate(-24deg);
    width: 60.5%;
    z-index: 2;
    bottom: 0;
    left: 0;
    transform-origin: 18% 50%;
  }

  .red {
    background: $red;
    transform: rotate(24deg);
    width: 61.5%;
    z-index: 3;
    right: 0;
    bottom: 0;
    transform-origin: 83% 50%;
  }

  .text {
    position: absolute;
    bottom: -40px;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
