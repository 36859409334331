import { userManager } from "@/services/security/user-manager";
import { getIdb, setIdb } from "@/indexed-db";
import { pictureManager } from "@/services/utilities/picture-manager";
import { defineStore } from "pinia";
import { useMainStore } from "@/store/main/main";

export const useUserStore = defineStore({
  id: "User",
  state: () => {
    return {
      user: {},
      userPicture: "",
      userType: "",
      userProfile: null,
      userProfileName: "",
      permissions: {},
    };
  },

  actions: {
    setUserInfos(user) {
      setIdb("ufaUser", JSON.stringify(user), "ufaUser");
      this.user = user;
    },

    setUserPermissions(permissions) {
      this.permissions = permissions;
    },

    init() {
      this.user = {};
      this.permissions = {};
      this.userProfile = null;
      this.userPicture = "";
      this.userProfileName = "";
    },

    setUserPicture(picture) {
      this.userPicture = pictureManager.setPicture(picture);
    },

    setUserProfile(userProfile) {
      this.userProfile = userProfile;
    },

    setUserProfileName(userProfileName) {
      this.userProfileName = userProfileName;
    },

    setUserNewMessage(userNewMessage) {
      this.user.userNewMessage = userNewMessage;
    },

    fetchUserInfos() {
      if (useMainStore().isInternet) {
        return userManager.loadUserInfos().then((user) => {
          if (user) {
            this.setUserInfos(user);
            this.setUserPicture(user.photo);
          }
        });
      } else {
        return getIdb("ufaUser", "ufaUser").then((user) => {
          this.setUserInfos(JSON.parse(user));
          useMainStore().setAppLoading(false);
        });
      }
    },

    fetchPermissions(trainingCourse) {
      return userManager.loadPermissions(trainingCourse).then((permissions) => {
        this.setUserPermissions(permissions);
      });
    },

    postUserUpdate(payload) {
      return userManager
        .putUserInfos(payload.id, payload.datas)
        .then((success) => success);
    },
  },
});
