<template>
  <FormBlockComponent
    :key="componentKey"
    :loading="loading"
    :models="models"
    :title="title"
    @formCancel="cancel"
    @submit="submit"
    @update="update"
  />
</template>

<script>
import FormBlockComponent from "@/components/blocks/FormBlockComponent.vue";
import { apiConnection } from "@/services/api-connection";
import { formManager } from "@/services/form/form-manager";
import { useMeta } from "@/composables/use-meta";
import { formValidator } from "@/services/form/form-validator";

export default {
  name: "SkillAddEditView",
  data() {
    return {
      componentKey: 0,
      loading: false,
      models: [],
      type: "",
      id: null,
      params: {},
      title: "",
      success: "",
      entity: "",
      backPath: "",
      maxSkill: null,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    maxSkill() {
      if (
        this.models["skillLevels"].vars.value.length >
        this.models["skillLevels"].vars.attr["data-max-tag"]
      ) {
        let iteration =
          this.models["skillLevels"].vars.choices.length -
          this.models["skillLevels"].vars.attr["data-max-tag"];
        for (let i = 0; i < iteration; i++) {
          this.models["skillLevels"].vars.value.pop();
        }
      }
    },
  },
  methods: {
    init() {
      this.id = this.$route.params.id ? this.$route.params.id : null;
      this.backPath = this.$route.meta.backPath;
      this.set();
      if (this.id) {
        this.params["id"] = this.id;
      }

      if (this.$route.query.date) {
        this.params["date"] = this.$route.query.date;
      }

      this.$route.meta.title = this.title;
      useMeta({ title: this.title });
      window.localStorage.setItem(
        "studea-pageTitle",
        JSON.stringify({ title: this.title, name: this.$route.name }),
      );
      this.loading = true;
      apiConnection
        .get(
          "/app/studea-manager/" +
            this.$route.params.trainingCourseId +
            "/skill/" +
            this.type +
            "-form",
          this.params,
        )
        .then((data) => {
          if (data) {
            this.models = data;
            this.componentKey++;
            this.update();
          }
          this.loading = false;
        });
    },

    update() {
      const maxSkillLevel = this.models["maxSkillLevel"];
      const skillLevels = this.models["skillLevels"];
      if (maxSkillLevel && skillLevels) {
        let nb = maxSkillLevel.vars.value.data
          ? maxSkillLevel.vars.value.data
          : maxSkillLevel.vars.value;
        nb = parseInt(nb);
        skillLevels.vars.attr["data-min-tag"] = nb;
        skillLevels.vars.attr["data-max-tag"] = nb;
        const nbLevel = Object.keys(skillLevels.vars.value).length;
        if (
          nb &&
          nb !== nbLevel &&
          !Object.keys(skillLevels.vars.value).length
        ) {
          if (nb >= 2) {
            skillLevels.vars.choices = [
              {
                value: this.$t("studea_manager.skill.default.not_acquired"),
                data: this.$t("studea_manager.skill.default.not_acquired"),
                label: this.$t("studea_manager.skill.default.not_acquired"),
              },
            ];
          }
          if (nb >= 3) {
            skillLevels.vars.choices.push({
              value: this.$t("studea_manager.skill.default.being_acquired"),
              data: this.$t("studea_manager.skill.default.being_acquired"),
              label: this.$t("studea_manager.skill.default.being_acquired"),
            });
          }
          if (nb >= 2) {
            skillLevels.vars.choices.push({
              value: this.$t("studea_manager.skill.default.acquired"),
              data: this.$t("studea_manager.skill.default.acquired"),
              label: this.$t("studea_manager.skill.default.acquired"),
            });
          }
          if (nb >= 4) {
            skillLevels.vars.choices.push({
              value: this.$t("studea_manager.skill.default.expert"),
              data: this.$t("studea_manager.skill.default.expert"),
              label: this.$t("studea_manager.skill.default.expert"),
            });
          }
          if (nb >= 5) {
            skillLevels.vars.choices = [
              {
                value: this.$t(
                  "studea_manager.skill.default.insufficient_mastery",
                ),
                data: this.$t(
                  "studea_manager.skill.default.insufficient_mastery",
                ),
                label: this.$t(
                  "studea_manager.skill.default.insufficient_mastery",
                ),
              },
              {
                value: this.$t("studea_manager.skill.default.partial_mastery"),
                data: this.$t("studea_manager.skill.default.partial_mastery"),
                label: this.$t("studea_manager.skill.default.partial_mastery"),
              },
              {
                value: this.$t("studea_manager.skill.default.average_mastery"),
                data: this.$t("studea_manager.skill.default.average_mastery"),
                label: this.$t("studea_manager.skill.default.average_mastery"),
              },
              {
                value: this.$t("studea_manager.skill.default.good_mastery"),
                data: this.$t("studea_manager.skill.default.good_mastery"),
                label: this.$t("studea_manager.skill.default.good_mastery"),
              },
              {
                value: this.$t(
                  "studea_manager.skill.default.excellent_mastery",
                ),
                data: this.$t("studea_manager.skill.default.excellent_mastery"),
                label: this.$t(
                  "studea_manager.skill.default.excellent_mastery",
                ),
              },
            ];
          }
        }
      }
      this.maxSkill = skillLevels.vars.attr["data-max-tag"];
      formValidator.validModel(skillLevels);
    },

    set() {
      if (this.$route.path.search("configuration") !== -1) {
        this.title = this.$t("studea_manager.skill_configuration.title");
        this.success = this.$t("studea_manager.skill_configuration.success");
        this.type = "config";
        this.entity = "training-course";
        this.id = this.$route.params.trainingCourseId;
      } else if (this.$route.path.search("generate-questionnaire") !== -1) {
        this.title = this.$t("studea_manager.skill_generate.title");
        this.success = this.$t("studea_manager.skill_generate.success");
      }
    },

    submit() {
      const result = formManager.processForm(this.models);
      this.loading = true;
      const url =
        "/app/studea-manager/" +
        this.$route.params.trainingCourseId +
        "/generic/" +
        this.entity +
        "/post" +
        (this.id ? "/" + this.id : "");
      (this.id
        ? apiConnection.put(url, result)
        : apiConnection.post(url, result)
      ).then((data) => {
        const route = {
          name: "studeaManagerSkillList",
        };
        formManager.processResponse(data, route, this.success, this.models);
        this.componentKey++;
        this.loading = false;
      });
    },

    cancel() {
      this.$router.push({ name: this.backPath });
    },
  },
  components: {
    FormBlockComponent,
  },
};
</script>

<style lang="scss" scoped></style>
