<template>
  <FormBlockComponent
    :key="componentKey"
    :loading="loading"
    :models="models"
    :title="title"
    @formCancel="cancel"
    @submit="submit"
  >
    <template v-slot:infos>
      <div class="errors" v-if="Object.keys(reports).length">
        <h6 class="mb-2 fw-bold">
          <FontAwesomeIcon icon="fa-light fa-triangle-exclamation" />
          {{ $t("global.import.errors") }}
        </h6>
        <div class="error" v-for="error in reports" :key="error">
          - {{ error }}
        </div>
      </div>
      <div class="mb-3 textStyle">
        <a v-if="!update" class="btn btnStyle btn-primary mb-2" :href="model">{{
          $t("studea_manager.import.modele")
        }}</a>
        <button
          v-else
          class="btn btn-primary btnStyle mb-2"
          @click="updateModel"
        >
          {{ $t("studea_manager.download_file_here") }}
        </button>

        <div class="text-danger" v-if="alias === 'triad' && !update">
          {{ $t("studea_manager.import.triad_new_file") }}
        </div>
        <div class="text-danger" v-if="alias === 'triad' && update">
          {{ $t("studea_manager.import.triad_update") }}
        </div>
        <div>
          {{ $t("studea_manager.import.thanks") }}
        </div>
        <div class="mt-3" v-if="alias === 'triad' && update">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ptIQ1FPtDk0"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </template>
  </FormBlockComponent>
</template>

<script>
import FormBlockComponent from "@/components/blocks/FormBlockComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import { mapState } from "pinia";
import { stringManager } from "@/services/utilities/string-manager";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { loginManager } from "@/services/security/login-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useStudeaManagerStore } from "@/store/studea_manager/studea-manager";

export default {
  name: "ImportView",
  components: { FontAwesomeIcon, FormBlockComponent },
  data() {
    return {
      models: [],
      componentKey: 0,
      alias: "",
      id: null,
      loading: false,
      params: {},
      title: "",
      current: "",
      tradPrefix: "",
      edit: false,
      generic: true,
      platform: loginManager.getApiPath().slice(0, -3),
      update: false,
      backPath: "",
      reports: {},
    };
  },
  watch: {
    menu() {
      if (Object.keys(this.menu).length) {
        this.getCurrentMenuEntry();
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    model() {
      let file = "";
      switch (this.alias) {
        case "triad":
          file = "trinomes-studea.xlsx";
          break;
        case "skill":
          file = "modele_import_competences.xlsx";
          break;
        case "planning":
          file = "import_planning.xls";
          break;
        case "absence":
          file = "import_absence.xls";
          break;
      }
      return this.platform + "models/" + file;
    },
    ...mapState(useStudeaManagerStore, {
      menu: (store) => store.menu,
    }),
  },
  methods: {
    submit() {
      const result = formManager.processForm(this.models);
      result["trainingCourse"] = { id: this.$route.params.trainingCourseId };
      result["update"] = this.update;
      this.loading = true;
      let urlCall =
        "/app/studea-manager/" +
        this.$route.params.trainingCourseId +
        "/" +
        encodeURI(this.alias) +
        "/import-post";
      apiConnection.post(urlCall, result).then((data) => {
        const message = this.$tc(
          "global." + (this.update ? "update" : "import") + ".success",
          2,
          {
            name: this.$tc("studea_manager." + this.tradPrefix + ".title", 2),
          },
        );
        const route = {
          name: this.backPath,
          params: { entity: this.alias },
        };
        this.reports = formManager.processReporting(data, route, message);
        this.componentKey++;
        this.loading = false;
      });
    },

    cancel() {
      this.$router.push({
        name: this.backPath,
        params: { entity: this.alias },
      });
    },

    getCurrentMenuEntry() {
      let current = null;
      Object.values(this.menu).forEach((item) => {
        if (!current) {
          current = Object.values(item.children).find(
            (item) => item.params && item.params.alias === this.alias,
          );
        }
      });
      this.current = current;
    },

    updateModel() {
      studeaManagerManager.export(
        this.alias,
        this.$route.params.trainingCourseId,
      );
    },

    init() {
      this.update = this.$route.path.includes("update");
      this.loading = true;
      this.backPath = this.$route.meta.backPath;
      this.alias = this.$router.currentRoute.value.params.alias;
      this.tradPrefix = stringManager.toCamelCase(this.alias);
      this.title = this.$tc(
        "global." + (this.update ? "update" : "import") + ".title",
        2,
        {
          name: this.$tc(
            "studea_manager." + this.tradPrefix + ".title",
            2,
          ).toLowerCase(),
        },
      );

      studeaManagerManager
        .importForm(this.alias, this.$route.params.trainingCourseId)
        .then((data) => {
          this.models = data;
          this.componentKey++;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-content {
  padding: 20px;
}

.errors {
  border: 1px solid $red;
  border-radius: 6px;
  color: $red;
  padding: 15px;
  font-size: 14px;
  margin-bottom: 15px;
  width: fit-content;
}
</style>
