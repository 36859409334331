<template>
  <FormBlockComponent
    :key="componentKey"
    :loading="loading"
    :models="models"
    :title="title"
    @formCancel="cancel"
    @submit="submit"
  >
    <template v-slot:infos>
      <div class="errors" v-if="Object.keys(reports).length">
        <h6 class="mb-2 fw-bold">
          <FontAwesomeIcon icon="fa-light fa-triangle-exclamation" />
          {{ $t("global.import.errors") }}
        </h6>
        <div class="error" v-for="error in reports" :key="error">
          - {{ error }}
        </div>
      </div>
      <div class="mb-3 textStyle">
        <a v-if="!update" :href="model" class="btn btnStyle btn-primary mb-2">{{
          $t("admin.import.modele")
        }}</a>
        <div>
          {{ $t("admin.import.thanks") }}
        </div>
      </div>
    </template>
  </FormBlockComponent>
</template>

<script>
import FormBlockComponent from "@/components/blocks/FormBlockComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import { mapState } from "pinia";
import { stringManager } from "@/services/utilities/string-manager";
import { loginManager } from "@/services/security/login-manager";
import { adminManager } from "@/services/admin/admin-manager";
import { useAdminStore } from "@/store/admin/admin";

export default {
  name: "ImportView",
  components: { FormBlockComponent },
  data() {
    return {
      models: [],
      componentKey: 0,
      alias: "",
      id: null,
      loading: false,
      params: {},
      title: "",
      current: "",
      tradPrefix: "",
      edit: false,
      generic: true,
      platform: loginManager.getApiPath().slice(0, -3),
      update: false,
      reports: {},
    };
  },
  watch: {
    menu() {
      if (Object.keys(this.menu).length) {
        this.getCurrentMenuEntry();
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    model() {
      let file = "";
      switch (this.alias) {
        case "training":
          file = "import_formations.xlsx";
          break;
        case "triad":
          file = "import_triad_by_training_course.xlsx";
          break;
      }
      return this.platform + "models/" + file;
    },
    ...mapState(useAdminStore, {
      menu: (store) => store.menu,
    }),
  },
  methods: {
    submit() {
      const result = formManager.processForm(this.models);
      this.loading = true;
      let urlCall = "/app/admin/" + encodeURI(this.alias) + "/import-post";

      apiConnection.post(urlCall, result).then((data) => {
        const message = this.$tc(
          "global." + (this.update ? "update" : "import") + ".success",
          2,
          {
            name: this.$tc("admin." + this.tradPrefix + ".title", 2),
          },
        );
        const route = {
          name: this.$route.meta.backPath
            ? this.$route.meta.backPath
            : "adminUsersList",
          params: { entity: this.alias },
        };
        this.reports = formManager.processReporting(data, route, message);
        this.componentKey++;
        this.loading = false;
      });
    },

    cancel() {
      this.$router.push({
        name: this.$route.meta.backPath
          ? this.$route.meta.backPath
          : "adminUsersList",
        params: { entity: this.alias },
      });
    },

    getCurrentMenuEntry() {
      let current = null;
      Object.values(this.menu).forEach((item) => {
        if (!current) {
          current = Object.values(item.children).find(
            (item) => item.params && item.params.alias === this.alias,
          );
        }
      });
      this.current = current;
    },

    init() {
      this.alias = this.$router.currentRoute.value.params.alias;
      this.tradPrefix = stringManager.toCamelCase(this.alias);
      this.title = this.$tc("global.import.title", 2, {
        name: this.$tc("admin." + this.tradPrefix + ".title", 2).toLowerCase(),
      });
      this.update = this.$route.path.includes("update");
      this.loading = true;

      adminManager.importForm(this.alias).then((data) => {
        this.models = data;
        this.componentKey++;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-content {
  padding: 20px;
}
</style>
