<template>
  <div class="row">
    <div class="col-lg-9 order-1 order-lg-0">
      <SessionTabsBlock
        v-if="currentSession && currentSession.id"
        :currentSession="currentSession"
        :sessions="skills"
        @changeSession="changeSession"
        @deleteSession="deleteSession"
      />
      <div v-else class="widget p-2 empty-session">
        <h6 class="textStyle mb-3">
          {{
            $tc("global.empty.title", 2, {
              name1: this.$tc("studea_manager.skill.title", 1).toLowerCase(),
              name2: this.$tc(
                "studea_manager.skillSession.title",
                1,
              ).toLowerCase(),
            })
          }}
        </h6>
        <router-link
          class="btn btnStyle btn-primary"
          :to="{
            name: 'studeaManagerSkillSessionAdd',
            params: { alias: 'skill-session' },
          }"
          v-tooltip="{
            title: $tc('global.add.title', 2, {
              name: $tc('studea_manager.skillSession.title', 1).toLowerCase(),
            }),
          }"
          v-access:[permissions]="accessManager().EDIT"
        >
          <FontAwesomeIcon icon="fa-litgh fa-plus" />
          <div class="ms-2">
            {{
              $tc("global.add.title", 2, {
                name: $tc("studea_manager.skillSession.title", 1).toLowerCase(),
              })
            }}
          </div>
        </router-link>
      </div>
      <div class="loader-block">
        <VueDraggableNext
          :animation="200"
          :delay="200"
          :delay-on-touch-only="true"
          :group="{ name: 'categories' }"
          :list="skills"
          handle=".grip"
          :disabled="
            !accessManager().isAvailableForUser(
              this.$route,
              studeaManagerManager().STUDEA_MANAGER_SKILLS,
              accessManager().EDIT,
            )
          "
          @change="sortCategory"
        >
          <SkillCategoryComponent
            v-if="
              currentSession &&
              currentSession.categories &&
              currentSession.categories.length
            "
            :skills="currentSession.categories"
            :currentSession="currentSession.id"
            @init="init"
          />
          <div
            class="widget empty"
            v-else-if="currentSession && currentSession.id"
          >
            <h6 class="textStyle mb-3">
              {{
                $tc("global.empty.title", 2, {
                  name1: this.$tc(
                    "studea_manager.skill.title",
                    1,
                  ).toLowerCase(),
                  name2: this.$tc(
                    "studea_manager.skillCategory.title",
                    1,
                  ).toLowerCase(),
                })
              }}
            </h6>
            <router-link
              class="btn btnStyle btn-primary"
              :to="{
                name: 'studeaManagerSkillCategoryAdd',
                params: {
                  alias: 'skill-category',
                },
                query: {
                  currentSession:
                    this.currentSession && this.currentSession.id
                      ? this.currentSession.id
                      : null,
                },
              }"
              v-access:[permissions]="accessManager().EDIT"
            >
              <FontAwesomeIcon icon="fa-light fa-plus" />
              <div class="ms-2">
                {{
                  $tc("global.add.title", 2, {
                    name: this.$tc(
                      "studea_manager.skillCategory.title",
                      1,
                    ).toLowerCase(),
                  })
                }}
              </div>
            </router-link>
          </div>
        </VueDraggableNext>
        <BlockLoader :loading="loading" />
      </div>
    </div>
    <div class="col-lg-3 order-0 order-lg-1">
      <div class="sticky">
        <div id="action-blocks">
          <ActionsBlock
            :actions="moduleActions"
            :mobileCollapsable="true"
            :collapsable="false"
            :title="$t('studea_manager.skill.actions.module_actions')"
          />
          <ActionsBlock
            v-if="currentSession || (currentSession && currentSession.id)"
            :actions="skillActions"
            :collapsable="true"
            :title="$t('studea_manager.skill.actions.skill_actions')"
            collapseId="skillActions"
            @removeSkills="removeSkills"
          />
          <ActionsBlock
            v-if="currentSession || (currentSession && currentSession.id)"
            :actions="importExportActions"
            :collapsable="true"
            :title="$t('studea_manager.skill.actions.import_export_actions')"
            collapseId="importExportActions"
            @export="exportSkills"
            @exportTaught="exportTaught"
            @import="importFranceCompetence"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notificationManager } from "@/services/utilities/notification-manager";
import { VueDraggableNext } from "vue-draggable-next";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import SkillCategoryComponent from "@/components/skills/SkillCategoryComponent.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { apiConnection } from "@/services/api-connection";
import SessionTabsBlock from "@/components/blocks/SessionTabsBlock.vue";
import { mapActions, mapState } from "pinia";
import { accessManager } from "@/services/security/access-manager";
import { useTriadStore } from "@/store/triad/triad";
import { useUserStore } from "@/store/user/user";

export default {
  name: "SkillListView",
  components: {
    SessionTabsBlock,
    FontAwesomeIcon,
    BlockLoader,
    SkillCategoryComponent,
    ActionsBlock,
    VueDraggableNext,
  },
  data() {
    return {
      moduleActions: [
        {
          translation: "global.list.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc("studea_manager.skillType.title", 1).toLowerCase(),
          },
          icon: "eye",
          type: "success",
          actionType: "router",
          action: "studeaManagerSkillTypeList",
          cfaStyle: false,
          params: { alias: "skill-type" },
          access: accessManager.VIEW,
        },
        {
          translation: "studea_manager.skill_configuration.title",
          icon: "cog",
          type: "success",
          actionType: "router",
          action: "studeaManagerSkillConfiguration",
          cfaStyle: false,
          access: accessManager.EDIT,
        },
      ],
      skillActions: [],
      importExportActions: [
        {
          translation: "global.import.title",
          translationGender: 2,
          translationParams: {
            name: this.$tc("studea_manager.skill.title", 2).toLowerCase(),
          },
          icon: "upload",
          type: "success",
          actionType: "router",
          action: "studeaManagerSkillImport",
          cfaStyle: false,
          params: { alias: "skill" },
          access: accessManager.EDIT,
        },
        {
          translation: "studea_manager.skill_import_france_competence.title",
          icon: "upload",
          type: "success",
          action: "import",
          cfaStyle: false,
          access: accessManager.EDIT,
        },
        {
          translation: "global.export.title",
          translationGender: 2,
          translationParams: {
            name: this.$tc("studea_manager.skill.title", 2).toLowerCase(),
          },
          icon: "download",
          type: "success",
          action: "export",
          cfaStyle: false,
        },
        {
          translation: "studea_manager.skill_export_taught.title",
          icon: "download",
          type: "success",
          action: "exportTaught",
          cfaStyle: false,
        },
      ],
      questionnaireModel: this.questionnaire,
      showModal: false,
      models: [],
      list: {},
      categories: [],
      years: {},
      skills: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      currentSession: (store) => store.currentSession,
    }),
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  mounted() {
    this.setCurrentSession(null);
    this.init();
  },
  methods: {
    studeaManagerManager() {
      return studeaManagerManager;
    },
    accessManager() {
      return accessManager;
    },
    sortCategory(event) {
      const id = event.moved.element.id;
      const position = event.moved.newIndex;
      studeaManagerManager
        .sort(
          "skill-category",
          this.$route.params.trainingCourseId,
          id,
          position,
        )
        .then(() => {
          this.init();
        });
    },

    removeSkills() {
      notificationManager
        .showAlert(
          "error",
          this.$t("studea_manager.skill_remove.title"),
          this.$t("studea_manager.skill_remove.sub_title"),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            apiConnection
              .get(
                "/app/studea-manager/" +
                  this.$route.params.trainingCourseId +
                  "/skill/remove-repository",
              )
              .then((response) => {
                if (response) {
                  notificationManager.showNotification(
                    "success",
                    this.$t("studea_manager.skill_remove.success"),
                  );
                } else {
                  notificationManager.showNotification(
                    "error",
                    this.$t("signed_error"),
                  );
                }
              });
          }
        });
    },

    importFranceCompetence() {
      notificationManager
        .showAlert(
          "info",
          this.$t("studea_manager.skill_import_france_competence.title"),
          this.$t("studea_manager.skill_import_france_competence.confirm"),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            apiConnection
              .get(
                "/app/studea-manager/" +
                  this.$route.params.trainingCourseId +
                  "/skill/france-competence",
              )
              .then((response) => {
                if (response) {
                  notificationManager.showNotification(
                    "success",
                    this.$t(
                      "studea_manager.skill_import_france_competence.success",
                    ),
                  );
                } else {
                  notificationManager.showNotification(
                    "error",
                    this.$t("signed_error"),
                  );
                }
              });
          }
        });
    },

    exportSkills() {
      studeaManagerManager.export("skill", this.$route.params.trainingCourseId);
    },

    exportTaught() {
      studeaManagerManager.export(
        "subject",
        this.$route.params.trainingCourseId,
      );
    },

    init() {
      this.loading = true;
      studeaManagerManager
        .list("skill", this.$route.params.trainingCourseId, false, [])
        .then((res) => {
          this.skills = res;
          this.loading = false;
          if (this.skills && this.skills.length) {
            if (
              (this.currentSession && !this.currentSession.id) ||
              !this.currentSession
            ) {
              this.setCurrentSession(
                this.skills.find((skill) => skill.currentSkillSession === true),
              );
            } else {
              let skillsToAdd = this.skills.find(
                (session) => session.id === this.currentSession.id,
              );
              this.currentSession.categories = skillsToAdd.categories;
            }
            if (this.currentSession && this.currentSession.id) {
              this.initActions();
            }
          }
        });
    },

    changeSession(id) {
      this.setCurrentSession(this.skills.find((skill) => skill.id === id));
      if (this.currentSession && this.currentSession.id) {
        this.initActions();
      }
    },

    deleteSession(payload) {
      notificationManager
        .showAlert(
          "error",
          this.$t("studea_manager.delete_session_confirmation"),
          "",
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            const url =
              "/app/studea-manager/" +
              this.$route.params.trainingCourseId +
              "/generic/skill-session/delete/" +
              payload;
            apiConnection.delete(url).then(() => {
              this.loading = true;
              this.setCurrentSession(null);
              this.init();
            });
          }
        });
    },

    initActions() {
      this.skillActions = [
        {
          translation: "global.add.title",
          translationGender: 2,
          translationParams: {
            name: this.$tc(
              "studea_manager.skillSession.title",
              1,
            ).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerSkillSessionAdd",
          cfaStyle: false,
          params: { alias: "skill-session" },
          access: accessManager.EDIT,
        },
        {
          translation: "global.add.title",
          translationGender: 2,
          translationParams: {
            name: this.$tc(
              "studea_manager.skillCategory.title",
              1,
            ).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerSkillCategoryAdd",
          cfaStyle: false,
          params: { alias: "skill-category" },
          query: { currentSession: this.currentSession.id },
          access: accessManager.EDIT,
        },
        {
          translation: "global.add.title",
          translationGender: 2,
          translationParams: {
            name: this.$tc("studea_manager.skill.title", 1).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerSkillAdd",
          cfaStyle: false,
          params: { alias: "skill" },
          query: { currentSession: this.currentSession.id },
          access: accessManager.EDIT,
        },
        {
          translation: "studea_manager.skill_generate.title",
          icon: "refresh",
          type: "success",
          actionType: "router",
          action: "studeaManagerSkillGenerateQuestionnaire",
          cfaStyle: false,
          params: { currentSessionId: this.currentSession.id },
          access: accessManager.EDIT,
        },
        {
          translation: "studea_manager.skill_remove.title",
          icon: "trash",
          type: "success",
          action: "removeSkills",
          cfaStyle: false,
          access: accessManager.DELETE,
        },
      ];
    },

    ...mapActions(useTriadStore, ["setCurrentSession"]),
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  padding: 20px;
}

.empty-session {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
