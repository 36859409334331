<template>
  <div id="timeline-block" class="widget widget-active-log">
    <div class="widget-heading">
      <h2 class="textStyle">Timeline</h2>
    </div>

    <div class="widget-content">
      <div class="w-shadow-top"></div>
      <div :class="'mt-container mx-auto mb-2' + (expanded ? ' all' : '')">
        <div class="timeline-line">
          <div
            v-for="item in timelines"
            :key="item.objectId"
            class="item-timeline"
          >
            <div class="t-dot">
              <div :class="getType(item.className)">
                <FontAwesomeIcon
                  :icon="'fa-light fa-' + getIcon(item.className)"
                />
              </div>
            </div>
            <div class="t-content">
              <div class="t-uppercontent">
                <h5 class="mb-0">{{ item.titlePart1 }}</h5>
                - {{ item.titlePart2 ? item.titlePart2 + " : " : "" }}
                <!--                <span class="fst-italic">{{ item.title }}</span>-->
              </div>
              <p>{{ format(item.minute) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="Object.keys(this.timelines).length > 4"
        :class="'widget-plus' + (this.expanded ? ' expanded' : '')"
      >
        <button class="widget-button bgStyle" @click="expand">
          <FontAwesomeIcon icon="fa-light fa-plus" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { mapState } from "pinia";
import { useTriadStore } from "@/store/triad/triad";

export default {
  name: "TimelineBlock",
  components: { FontAwesomeIcon },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      timelines: (store) => store.timelines,
    }),
  },
  methods: {
    expand() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.scrollTo();
      }
    },

    getType(className) {
      let type = "";
      switch (className) {
        case "App\\Entity\\Absence\\Absence":
          type = "absence";
          break;
        case "App\\Entity\\Absence\\Planning":
          type = "planning";
          break;
        case "App\\Entity\\Skill\\SkillReply":
          type = "skill";
          break;
        case "App\\Entity\\Document\\Document":
          type = "file";
          break;
        case "App\\Entity\\Questionnaire\\Reply":
          type = "questionnaire";
          break;
      }
      return type;
    },

    getIcon(className) {
      let icon = "";
      switch (className) {
        case "App\\Entity\\Absence\\Absence":
        case "App\\Entity\\Absence\\Planning":
          icon = "calendar-days";
          break;
        case "App\\Entity\\Skill\\SkillReply":
          icon = "medal";
          break;
        case "App\\Entity\\Document\\Document":
          icon = "file";
          break;
        case "App\\Entity\\Questionnaire\\Reply":
          icon = "file-signature";
          break;
      }
      return icon;
    },

    scrollTo() {
      const block = document.getElementById("timeline-block");
      const offset = 150;
      setTimeout(function () {
        window.scrollTo({
          behavior: "smooth",
          top:
            block.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offset,
        });
      }, 200);
    },

    format(date) {
      return dateFormatter.format(date, "short");
    },
  },
};
</script>

<style lang="scss" scoped>
.questionnaire {
  background: $green;
}

.absence,
.planning {
  background: $purple;
}

.file {
  background: $yellow;
}

.skill {
  background: $red;
}

.mt-container {
  overflow: hidden;
  height: auto !important;
  max-height: 320px;
  transition: all 300ms ease-in-out;

  &.all {
    max-height: 5000px;
  }
}

.widget-button {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);

  &:hover {
    background: var(--secondary-color);
  }
}

.widget-plus {
  &.expanded {
    .widget-button {
      transform: rotate(45deg);
    }
  }
}
</style>
