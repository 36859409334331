<template>
  <div class="widget skill-widget">
    <div class="widget-heading">
      <h2>{{ category.name }}</h2>
    </div>
    <div class="widget-content">
      <ApexChart
        v-if="isSeries"
        ref="chart"
        :key="key"
        :options="chartOptions"
        :series="series"
        type="radar"
      />
      <div v-else>{{ $t("stats.no_data") }}</div>
      <div v-if="isMobile">
        <div v-for="(skill, index) in labels" :key="skill.id">
          <span class="fw-bold">{{ index + 1 }}</span> : {{ skill }}
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading" />
  </div>
</template>

<script>
import ApexChart from "vue3-apexcharts";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { mapState } from "pinia";
import { useTrainingCourseStore } from "@/store/training-course/training-course";

export default {
  name: "SkillChartComponent",
  components: { BlockLoader, ApexChart },
  data() {
    return {
      loading: false,
      key: 0,
      labels: [],
      indexes: [],
      chart: {
        fontFamily: "Noto sans",
      },
      chartOptions: {
        chart: {
          id: "skillChart",
          toolbar: {
            show: false,
            offsetY: 10,
          },
          fontFamily: "Noto sans",
        },
        legend: {
          position: "left",
          floating: true,
          offsetY: -10,
          itemMargin: {
            vertical: 5,
          },
          markers: {
            offsetX: -5,
          },
          fontSize: "16px",
          labels: {
            colors: "var(--primary-color)",
          },
        },
        colors: ["#6ac7a4", "#00275e", "#da5164", "#f8a007"],
        responsive: [
          {
            breakpoint: 792,
            options: {
              chart: {
                height: 350,
              },
              legend: {
                position: "top",
                floating: false,
                offsetY: 0,
                fontSize: "14px",
              },
              xaxis: {
                labels: {
                  style: {
                    fontSize: "10px",
                  },
                },
              },
            },
          },
        ],
        yaxis: {
          max: undefined,
        },
      },
      series: null,
      skillOrder: [],
      isSeries: false,
      isMobile: false,
    };
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    currentSessionSkillReply: {
      type: Object,
      Required: true,
    },
  },
  computed: {
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
  },
  watch: {
    "triad.trainingCourse.skillCategories"() {
      this.init();
    },

    windowWidth() {
      this.isMobile = this.windowWidth < 768;
    },

    isMobile() {
      if (this.isMobile) {
        this.updateChart(this.indexes);
      } else {
        this.updateChart(this.labels);
      }
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    updateChart(labels) {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          yaxis: {
            max: this.trainingCourse.maxSkillLevel,
            min: 0,
          },
          xaxis: {
            categories: labels,
            labels: {
              style: {
                fontSize: "14px",
                fontWeight: 700,
              },
            },
          },
        },
      };
      this.key++;
    },

    init() {
      window.innerWidth < 768
        ? (this.isMobile = true)
        : (this.isMobile = false);
      this.setLabels();
      this.setSeries();
      this.isMobile
        ? this.updateChart(this.indexes)
        : this.updateChart(this.labels);
    },

    setLabels() {
      const labels = [];
      const indexes = [];
      const order = [];
      let count = 0;

      console.log(this.trainingCourse.maxSkillLevel);
      Object.values(this.category.skills).forEach((skill) => {
        labels.push(skill.name);
        indexes.push(count);
        count++;
        order.push(skill.id);
      });
      labels.filter((element) => element !== "skillSessionId");

      this.skillOrder = order;
      this.indexes = indexes;
      this.labels = labels;
    },

    setSeries() {
      const series = [];
      if (this.currentSessionSkillReply) {
        Object.entries(this.currentSessionSkillReply).forEach((profile) => {
          const key = profile[0];
          const object = profile[1];
          let answer;
          let data = [];

          if (object && object.skillAnswers) {
            Object.values(this.skillOrder).forEach((id) => {
              answer = object.skillAnswers.find(
                (answer) => answer.skill.id === id,
              );
              data.push(answer ? answer.value : "");
            });
          }
          series.push({ name: this.$t(key), data: data });
        });
      }

      this.series = series.filter(
        (element) => element.name !== "skillSessionId",
      );
      this.setIsSeries();
    },

    setIsSeries() {
      this.isSeries = false;
      if (this.series) {
        this.series.forEach((serie) => {
          if (serie.data.length) {
            serie.data.forEach((data) => {
              if (typeof data === "number") {
                this.isSeries = true;
              }
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  page-break-inside: avoid;
  break-inside: avoid;
}
</style>
