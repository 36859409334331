<template>
  <FormBlockComponent
    :key="componentKey"
    :loading="loading"
    :models="models"
    :title="title"
    @formCancel="cancel"
    @submit="submit"
  />
</template>

<script>
import FormBlockComponent from "@/components/blocks/FormBlockComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import { mapState, mapActions } from "pinia";
import { stringManager } from "@/services/utilities/string-manager";
import { adminManager } from "@/services/admin/admin-manager";
import { accessManager } from "@/services/security/access-manager";
import { useAdminStore } from "@/store/admin/admin";
import { useUserStore } from "@/store/user/user";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "AddEditView",
  components: { FormBlockComponent },
  data() {
    return {
      models: [],
      componentKey: 0,
      alias: "",
      id: null,
      loading: false,
      params: {},
      title: "",
      current: "",
      tradPrefix: "",
      edit: false,
      generic: true,
      unique: false,
      gender: 1,
      withCancel: false,
      backPath: "adminList",
    };
  },
  watch: {
    menu() {
      if (Object.keys(this.menu).length) {
        this.getCurrentMenuEntry();
        this.init();
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    accessManager.checkAccessByPermission(to, from, next);
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState(useAdminStore, {
      menu: (store) => store.menu,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  methods: {
    submit() {
      this.setNeedUpdate(true);
      const result = formManager.processForm(
        this.models,
        false,
        this.generic ? null : this.id,
      );

      if (this.alias === "document") {
        result["documentType"] = 1;
        result["triadDocument"] = false;
        result["uploader"] = { id: this.user.id };
      }

      this.loading = true;
      let urlCall =
        "/app/admin/" +
        (this.generic ? "generic/" : "") +
        encodeURI(this.alias) +
        "/" +
        (this.unique ? "unique-" : "") +
        (this.generic ? "post" : "");
      if (this.id) {
        urlCall =
          "/app/admin/" +
          (this.generic ? "generic/" : "") +
          encodeURI(this.alias) +
          (this.generic ? "/post" : "") +
          ("/" + this.id);
      }

      (this.id | this.unique
        ? apiConnection.put(urlCall, result)
        : apiConnection.post(urlCall, result)
      ).then((data) => {
        const message = this.$tc(
          "global." + (this.edit ? "edit" : "add") + ".success",
          this.gender,
          {
            name: this.$t("admin." + this.tradPrefix + ".title", 1),
          },
        );
        const route = {
          name: this.backPath,
          params: { alias: this.alias },
          query: { time: Date.now() },
        };
        if (this.alias === "data-agreement") {
          this.fetchDesign({ disableLoading: true });
        }
        formManager.processResponse(data, route, message, this.models);
        this.componentKey++;
        this.loading = false;
      });
    },

    cancel() {
      this.$router.push({
        name: this.backPath,
        params: { entity: this.alias },
      });
    },

    getCurrentMenuEntry() {
      let current = null;
      Object.values(this.menu).forEach((item) => {
        if (!current) {
          current = item.children.items.find(
            (item) => item.params && item.params.alias === this.alias,
          );
        }
      });
      this.current = current;
    },

    init() {
      this.loading = true;
      this.generic = !this.$route.meta.notGeneric;
      this.edit = this.id || this.$route.path.includes("edit");
      this.alias = this.$route.params.alias;
      this.withCancel = this.$route.meta.withCancel;

      this.unique =
        this.current && this.current.params
          ? this.current.params.unique
          : false;

      if (!this.unique) {
        this.unique = this.$route.meta.unique;
      }

      if (this.$route.meta.backPath) {
        this.backPath = this.$route.meta.backPath;
      } else if (this.$route.query.backPath) {
        this.backPath = this.$route.query.backPath;
      } else if (this.unique) {
        this.backPath = this.$route.name;
      }

      this.tradPrefix = stringManager.toCamelCase(this.alias);
      this.id = this.$route.params.id;
      this.gender = stringManager.gender(this.alias);
      this.title = this.$tc(
        "global." + (this.edit ? "edit" : "add") + ".title",
        this.gender,
        {
          name: this.$t("admin." + this.tradPrefix + ".title", 1).toLowerCase(),
        },
      );

      if (this.unique) {
        this.title = this.$t("admin." + this.tradPrefix + ".title", 1);
      }

      if (this.$route.query.set) {
        this.applyQuery();
      }

      adminManager
        .form(
          this.alias,
          this.id,
          this.params,
          this.generic,
          false,
          this.unique,
        )
        .then((data) => {
          this.models = data;

          if (this.unique && !this.withCancel) {
            this.models["submit"] = {
              vars: {
                block_prefixes: ["", "submit"],
                hideCancel: true,
                display: true,
                id: "submit",
              },
            };
          }

          this.componentKey++;
          this.loading = false;
        });
    },

    applyQuery() {
      const set = JSON.parse(this.$route.query.set);
      this.params["options"] = [];
      if (set && Object.keys(set).length) {
        let i = 0;
        Object.values(set).forEach((query) => {
          if (i > 0) {
            this.params.options = this.params.options.concat("&");
          }
          this.params.options = this.params.options.concat(
            query.name + "=" + query.value,
          );
          i++;
        });
      }
    },

    ...mapActions(usePlatformStore, ["setNeedUpdate", "fetchDesign"]),
  },
};
</script>

<style lang="scss" scoped>
.widget-content {
  padding: 20px;
}
</style>
