<template>
  <div class="widget">
    <div
      :aria-controls="
        collapsable || (mobileCollapsable && windowWidth < 991)
          ? 'collapse-' + collapseId
          : ''
      "
      :class="
        'widget-heading padding' +
        (collapsable || (mobileCollapsable && windowWidth < 991)
          ? ' collapsed'
          : '')
      "
      :data-bs-target="
        collapsable || (mobileCollapsable && windowWidth < 991)
          ? '#collapse-' + collapseId
          : ''
      "
      :data-bs-toggle="
        collapsable || (mobileCollapsable && windowWidth < 991)
          ? 'collapse'
          : ''
      "
      role="tab"
    >
      <h2 class="textStyle">{{ title ? title : $t("actions") }}</h2>
      <FontAwesomeIcon
        v-if="collapsable || (mobileCollapsable && windowWidth < 991)"
        class="chevron"
        icon="fa-light fa-chevron-up"
      />
    </div>
    <div
      :id="
        collapsable || (mobileCollapsable && windowWidth < 991)
          ? 'collapse-' + collapseId
          : ''
      "
      :aria-labelledby="
        collapsable || (mobileCollapsable && windowWidth < 991)
          ? 'collapse-' + collapseId
          : ''
      "
      :class="
        collapsable || (mobileCollapsable && windowWidth < 991)
          ? 'collapse'
          : ''
      "
    >
      <div class="widget-content">
        <div v-for="action in actions" :key="action.id">
          <div
            v-if="
              (action.condition &&
                row[action.condition] === action.conditionValue) ||
              (!action.condition && !action.conditionValue)
            "
            data-bs-parent="#action-blocks"
          >
            <div v-if="action.separation" class="separation"></div>
            <button
              v-if="!action.actionType || action.actionType === 'button'"
              v-access:[permissions]="action.access"
              :class="
                'btn me-2 mt-2 btn-' +
                action.type +
                (action.cfaStyle ? ' btnStyle' : '')
              "
              :disabled="action.disabled"
              v-tooltip="{
                title: $tc(
                  action.translation,
                  action.translationGender,
                  action.translationParams,
                ),
              }"
              @click="clickAction(action.action)"
            >
              <FontAwesomeIcon
                v-if="!action.loading || action.loading"
                :icon="'fa-light fa-' + action.icon"
              />
              <span
                v-else
                class="spinner-border text-white align-self-center loader-sm"
              ></span>
              {{
                $tc(
                  action.translation,
                  action.translationGender,
                  action.translationParams,
                )
              }}
            </button>
            <router-link
              v-else-if="action.actionType === 'router'"
              v-access:[permissions]="action.access"
              :class="
                'd-block btn mt-2 btn-' +
                action.type +
                (action.cfaStyle ? ' btnStyle' : '')
              "
              :disabled="action.disabled"
              :to="{
                name: action.action,
                params: action.params,
                query: action.query,
              }"
              v-tooltip="{
                title: $tc(
                  action.translation,
                  action.translationGender,
                  action.translationParams,
                ),
              }"
            >
              <FontAwesomeIcon :icon="'fa-light fa-' + action.icon" />
              {{
                $tc(
                  action.translation,
                  action.translationGender,
                  action.translationParams,
                )
              }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/store/user/user";

export default {
  name: "ActionsBlock",
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  props: {
    actions: {
      type: [],
      required: true,
      default: [],
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    collapsable: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileCollapsable: {
      type: Boolean,
      required: false,
      default: false,
    },
    collapseId: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  watch: {
    windowWidth() {
      this.updateWindowWidth();
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    clickAction(action) {
      this.$emit(action);
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;

  svg {
    margin-right: 8px;
  }
}

.separation {
  border-bottom: 1px dashed $m_color_3;
  width: 45%;
  margin: 15px auto 15px auto;
}

.spinner-border {
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  text-indent: -999em;
  border: 0.15em solid;
  border-color: currentColor transparent currentColor currentColor;
  border-radius: 50%;
  animation-name: spinnerBorder;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin-right: 5px;
}

@keyframes spinnerBorder {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.padding {
  @include mobile {
    padding: 10px 20px;
  }
}
</style>
