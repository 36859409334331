import LayoutView from "@/views/Admin/LayoutView.vue";
import AddEditView from "@/views/Admin/AddEditView.vue";
import ListView from "@/views/Admin/ListView.vue";
import UserAddEditView from "@/views/Admin/user/UserAddEditView.vue";
import UsersListView from "@/views/Admin/user/UsersListView.vue";
import TrainingsListView from "@/views/Admin/training/TrainingsListView.vue";
import { i18n } from "@/i18n";
import AdminHomeView from "@/views/Admin/AdminHomeView.vue";
import ExportView from "@/views/Admin/remote/ExportView.vue";
import InstitutionsListView from "@/views/Admin/institution/InstitutionsListView.vue";
import UsersProfileListView from "@/views/Admin/user/UsersProfileListView.vue";
import TrainingCourseEditView from "@/views/Admin/trainingCourse/TrainingCourseEditView.vue";
import NewsView from "@/views/News/NewsView.vue";
import NewsListView from "@/views/News/NewsListView.vue";
import DesignEditView from "@/views/Admin/design/DesignEditView.vue";
import ModulesListView from "@/views/Admin/module/ModulesListView.vue";
import ModulesTrainingCoursesView from "@/views/Admin/module/ModulesTrainingCoursesView.vue";
import ModuleAbsenceConfigView from "@/views/Admin/module/ModuleAbsenceConfigView.vue";
import ImportView from "@/views/Admin/ImportView.vue";
import GlobalLayoutView from "@/views/Global/GlobalLayoutView.vue";
import HistoricListView from "@/views/Admin/remote/HistoricListView.vue";
import StatisticView from "@/views/Admin/remote/StatisticView.vue";
import DuplicateView from "@/views/Admin/remote/DuplicateView.vue";
import ArchiveView from "@/views/Admin/remote/ArchiveView.vue";
import UsersProfileTitleListView from "@/views/Admin/user/UsersProfileTitleListView.vue";
import UsersProfileEditView from "@/views/Admin/user/UsersProfileEditView.vue";
import { accessManager } from "@/services/security/access-manager";
import { adminManager } from "@/services/admin/admin-manager";

export default {
  path: "/admin",
  name: "adminLayout",
  redirect: { name: "adminHome" },
  component: LayoutView,
  meta: {
    authRequired: true,
    required_role: "ROLE_STUDEA_ADMIN",
    title: i18n.global.t("admin.home.title"),
    breadcrumb: true,
  },
  children: [
    {
      path: "config",
      name: "adminHome",
      component: AdminHomeView,
      meta: {
        title: i18n.global.t("admin.home.title2"),
        breadcrumb: false,
      },
    },
    {
      path: "users",
      name: "adminUsers",
      redirect: { name: "adminUsersList" },
      component: GlobalLayoutView,
      meta: {
        title: i18n.global.tc("global.list.title", 1, {
          name: i18n.global.tc("admin.user.title", 2).toLowerCase(),
        }),
        breadcrumb: true,
        code: adminManager.STUDEA_ADMIN_USER,
        access: accessManager.VIEW,
      },
      children: [
        {
          path: "",
          name: "adminUsersList",
          component: UsersListView,
          meta: {
            title: i18n.global.tc("global.list.title", 1, {
              name: i18n.global.tc("admin.user.title", 2).toLowerCase(),
            }),
            breadcrumb: false,
            code: adminManager.STUDEA_ADMIN_USER,
            access: accessManager.VIEW,
          },
        },
        {
          path: "add",
          name: "adminUsersAdd",
          component: UserAddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 1, {
              name: i18n.global.tc("admin.user.title", 1).toLowerCase(),
            }),
            backPath: "adminUsers",
            breadcrumb: true,
            code: adminManager.STUDEA_ADMIN_USER,
            access: accessManager.EDIT,
          },
        },
        {
          path: "edit/:id",
          name: "adminUsersEdit",
          component: UserAddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global.tc("admin.user.title", 1).toLowerCase(),
            }),
            backPath: "adminUsers",
            breadcrumb: true,
            code: adminManager.STUDEA_ADMIN_USER,
            access: accessManager.EDIT,
          },
        },
        {
          path: "studea-manager-profile",
          name: "adminStudeaManagerProfileList",
          component: UsersProfileListView,
          meta: {
            title: i18n.global.tc("global.list.title", 1, {
              name: i18n.global
                .tc("admin.studeaManagerProfile.title", 2)
                .toLowerCase(),
            }),
            backPath: "adminUsers",
            breadcrumb: true,
            code: adminManager.STUDEA_ADMIN_ROLE,
            access: accessManager.VIEW,
          },
        },
        {
          path: ":alias(studea-manager-profile)/add",
          name: "adminStudeaManagerProfileAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 1, {
              name: i18n.global
                .tc("admin.studeaManagerProfile.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            notGeneric: true,
            backPath: "adminStudeaManagerProfileList",
            code: adminManager.STUDEA_ADMIN_ROLE,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(studea-manager-profile)/edit/:id",
          name: "adminStudeaManagerProfileEdit",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("admin.studeaManagerProfile.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            notGeneric: true,
            backPath: "adminStudeaManagerProfileList",
            code: adminManager.STUDEA_ADMIN_ROLE,
            access: accessManager.EDIT,
          },
        },
        {
          path: "profile-title-list",
          name: "UsersProfileTitleList",
          component: UsersProfileTitleListView,
          meta: {
            title: i18n.global.t("admin.user.profile_title"),
            breadcrumb: true,
          },
        },
        {
          path: "profile-title-edit/:id",
          name: "UserProfileEdit",
          component: UsersProfileEditView,
          meta: {
            title: i18n.global.t("admin.user.modify_profile"),
            breadcrumb: true,
          },
        },
        {
          path: ":alias(triad)/import-triad",
          name: "UserImportTriad",
          component: ImportView,
          meta: {
            title: i18n.global.t("admin.user.import_triad"),
            name: i18n.global.t("admin.user.import_triad"),
            breadcrumb: true,
            code: adminManager.STUDEA_ADMIN_MODULE,
            access: accessManager.EDIT,
          },
        },
      ],
    },
    {
      path: "trainings",
      name: "adminTrainings",
      redirect: { name: "adminTrainingsList" },
      component: GlobalLayoutView,
      meta: {
        title: i18n.global.tc("global.list.title", 2, {
          name: i18n.global.tc("admin.training.title", 2).toLowerCase(),
        }),
        breadcrumb: true,
        code: adminManager.STUDEA_ADMIN_TRAINING,
        access: accessManager.VIEW,
      },
      children: [
        {
          path: "",
          name: "adminTrainingsList",
          component: TrainingsListView,
          meta: {
            title: i18n.global.tc("global.list.title", 2, {
              name: i18n.global.tc("admin.training.title", 2).toLowerCase(),
            }),
            breadcrumb: false,
            code: adminManager.STUDEA_ADMIN_TRAINING,
            access: accessManager.VIEW,
          },
        },
        {
          path: ":alias(training)/add",
          name: "adminTrainingsAdd",
          component: AddEditView,
          meta: {
            breadcrumb: true,
            title: i18n.global.tc("global.add.title", 2, {
              name: i18n.global.tc("admin.training.title", 1).toLowerCase(),
            }),
            notGeneric: true,
            backPath: "adminTrainingsList",
            code: adminManager.STUDEA_ADMIN_TRAINING,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(training)/edit/:id",
          name: "adminTrainingsEdit",
          component: AddEditView,
          meta: {
            breadcrumb: true,
            title: i18n.global.tc("global.edit.title", 2, {
              name: i18n.global.tc("admin.training.title", 1).toLowerCase(),
            }),
            notGeneric: true,
            backPath: "adminTrainingsList",
            code: adminManager.STUDEA_ADMIN_TRAINING,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(training)/import",
          name: "adminTrainingsImport",
          component: ImportView,
          meta: {
            breadcrumb: true,
            title: i18n.global.tc("global.import.title", 2, {
              name: i18n.global.tc("admin.training.title", 1).toLowerCase(),
            }),
            backPath: "adminTrainingsList",
            code: adminManager.STUDEA_ADMIN_TRAINING,
            access: accessManager.EDIT,
          },
        },
        {
          path: "training-course/edit/:id",
          name: "adminTrainingCourseEdit",
          component: TrainingCourseEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("admin.trainingCourse.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "adminTrainingsList",
            code: adminManager.STUDEA_ADMIN_TRAINING,
            access: accessManager.EDIT,
          },
        },
      ],
    },
    {
      path: "institutions",
      name: "adminInstitutions",
      component: InstitutionsListView,
      meta: {
        title: i18n.global.tc("global.edit.title", 1, {
          name: i18n.global.tc("admin.institution.title", 2).toLowerCase(),
        }),
        breadcrumb: true,
        code: adminManager.STUDEA_ADMIN_INSTITUTION,
        access: accessManager.VIEW,
      },
    },
    {
      path: ":alias(help-document)",
      name: "adminHelpDocument",
      redirect: { name: "adminHelpDocumentSettings" },
      meta: {
        title: i18n.global.t("admin.help_settings"),
        breadcrumb: true,
        generic: "admin",
        code: adminManager.STUDEA_ADMIN_HELP,
        access: accessManager.VIEW,
      },
      children: [
        {
          path: "settings",
          name: "adminHelpDocumentSettings",
          component: DesignEditView,
          meta: {
            title: i18n.global.t("admin.help_settings"),
            breadcrumb: true,
            code: adminManager.STUDEA_ADMIN_HELP,
            access: accessManager.EDIT,
          },
        },
      ],
    },
    {
      path: "modules",
      name: "adminModule",
      redirect: { name: "adminModuleList" },
      component: GlobalLayoutView,
      meta: {
        title: i18n.global.tc("global.list.title", 1, {
          name: i18n.global.tc("admin.modulet.title", 2).toLowerCase(),
        }),
        breadcrumb: true,
        code: adminManager.STUDEA_ADMIN_MODULE,
        access: accessManager.VIEW,
      },
      children: [
        {
          path: "",
          name: "adminModuleList",
          component: ModulesListView,
          meta: {
            title: i18n.global.tc("global.list.title", 1, {
              name: i18n.global.tc("admin.modulet.title", 2).toLowerCase(),
            }),
            breadcrumb: false,
            code: adminManager.STUDEA_ADMIN_MODULE,
            access: accessManager.VIEW,
          },
        },
        {
          path: "training-course/:id",
          name: "adminModuleTrainingCourses",
          component: ModulesTrainingCoursesView,
          meta: {
            title: i18n.global.t("admin.modulet.trainingCourse.edit.title"),
            breadcrumb: true,
            code: adminManager.STUDEA_ADMIN_MODULE,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias",
          name: "adminModuleQuestionnaireConfig",
          component: AddEditView,
          meta: {
            title: i18n.global.t("admin.modulet.config.title"),
            breadcrumb: true,
            unique: true,
            withCancel: true,
            backPath: "adminModuleList",
            code: adminManager.STUDEA_ADMIN_MODULE,
            access: accessManager.EDIT,
          },
        },
        {
          path: "absence-config",
          name: "adminModuleAbsenceConfig",
          component: ModuleAbsenceConfigView,
          meta: {
            title: i18n.global.t("admin.modulet.config.title"),
            breadcrumb: true,
            code: adminManager.STUDEA_ADMIN_MODULE,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(absence-reason)/add",
          name: "adminAbsenceReasonAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 1, {
              name: i18n.global
                .tc("admin.absenceReason.title", 1)
                .toLowerCase(),
            }),
            backPath: "adminModuleAbsenceConfig",
            breadcrumb: true,
            code: adminManager.STUDEA_ADMIN_MODULE,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(absence-reason)/edit/:id",
          name: "adminAbsenceReasonEdit",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("admin.absenceReason.title", 1)
                .toLowerCase(),
            }),
            backPath: "adminModuleAbsenceConfig",
            breadcrumb: true,
            code: adminManager.STUDEA_ADMIN_MODULE,
            access: accessManager.EDIT,
          },
        },
      ],
    },
    {
      path: ":alias/list",
      name: "adminList",
      component: ListView,
      meta: {
        title: "Liste",
        breadcrumb: true,
        generic: "admin",
        access: accessManager.VIEW,
      },
    },
    {
      path: ":alias/add",
      name: "adminAdd",
      component: AddEditView,
      meta: {
        title: "Ajouter",
        breadcrumb: true,
        generic: "admin",
        access: accessManager.EDIT,
      },
    },
    {
      path: ":alias/edit/:id",
      name: "adminEdit",
      component: AddEditView,
      meta: {
        title: "Modifier",
        breadcrumb: true,
        generic: "admin",
        access: accessManager.EDIT,
      },
    },
    {
      path: "design/edit",
      name: "adminDesign",
      component: DesignEditView,
      meta: {
        title: i18n.global.tc("global.edit.title", 1, {
          name: i18n.global.tc("admin.design.title", 1).toLowerCase(),
        }),
        breadcrumb: true,
        code: adminManager.STUDEA_ADMIN_DESIGN,
        access: accessManager.EDIT,
      },
    },
    {
      path: ":alias(data-agreement)/edit",
      name: "adminDataAgreement",
      component: AddEditView,
      meta: {
        title: i18n.global.tc("global.edit.title", 0, {
          name: i18n.global.tc("admin.dataAgreement.title", 1).toLowerCase(),
        }),
        breadcrumb: true,
        code: adminManager.STUDEA_ADMIN_DATA_AGREEMENT,
        access: accessManager.EDIT,
      },
    },
    {
      path: "history",
      name: "adminHistory",
      component: HistoricListView,
      meta: {
        title: i18n.global.t("admin.historic.title"),
        breadcrumb: true,
        code: adminManager.STUDEA_ADMIN_HISTORY,
        access: accessManager.ALL,
      },
    },
    {
      path: "statistic",
      name: "adminStatistic",
      component: StatisticView,
      meta: {
        title: i18n.global.t("admin.statistic.title"),
        breadcrumb: true,
        code: adminManager.STUDEA_ADMIN_STATISTIC,
        access: accessManager.ALL,
      },
    },
    {
      path: "export",
      name: "adminExport",
      component: ExportView,
      meta: {
        title: i18n.global.t("admin.export.title"),
        breadcrumb: true,
        code: adminManager.STUDEA_ADMIN_EXPORT,
        access: accessManager.ALL,
      },
    },
    {
      path: "duplicate",
      name: "adminDuplicate",
      component: DuplicateView,
      meta: {
        title: i18n.global.t("admin.duplicate.title"),
        breadcrumb: true,
        code: adminManager.STUDEA_ADMIN_DUPLICATE,
        access: accessManager.ALL,
      },
    },
    {
      path: "archive",
      name: "adminArchive",
      component: ArchiveView,
      meta: {
        title: i18n.global.t("admin.archive.title"),
        breadcrumb: true,
        code: adminManager.STUDEA_ADMIN_ARCHIVE,
        access: accessManager.ALL,
      },
    },
    {
      path: "admin-news",
      name: "adminNewsLayout",
      redirect: { name: "adminNewsList" },
      component: GlobalLayoutView,
      meta: {
        title: i18n.global.t("admin.news.list.title"),
        breadcrumb: true,
      },
      children: [
        {
          path: "",
          name: "adminNewsList",
          component: NewsListView,
          meta: {
            title: i18n.global.t("admin.news.list.title"),
            breadcrumb: false,
          },
        },
        {
          path: ":id",
          name: "adminNewsShow",
          component: NewsView,
          meta: {
            title: i18n.global.t("admin.news.list.title"),
            breadcrumb: true,
          },
        },
      ],
    },
  ],
};
