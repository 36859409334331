<template>
  <FormBlockComponent
    :key="componentKey"
    :loading="loading"
    :models="models"
    :title="title"
    @formCancel="cancel"
    @submit="submit"
  />
</template>

<script>
import FormBlockComponent from "@/components/blocks/FormBlockComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import { mapState } from "pinia";
import { stringManager } from "@/services/utilities/string-manager";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { useStudeaManagerStore } from "@/store/studea_manager/studea-manager";
import { useUserStore } from "@/store/user/user";

export default {
  name: "AddEditView",
  components: { FormBlockComponent },
  data() {
    return {
      models: [],
      componentKey: 0,
      alias: "",
      id: null,
      loading: false,
      params: {},
      title: "",
      current: "",
      tradPrefix: "",
      edit: false,
      generic: true,
      postGeneric: true,
      putGeneric: true,
      unique: false,
      gender: 1,
      backPath: "",
    };
  },
  watch: {
    menu() {
      if (Object.keys(this.menu).length) {
        this.getCurrentMenuEntry();
        this.init();
      }
    },
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState(useStudeaManagerStore, {
      menu: (store) => store.menu,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  methods: {
    submit() {
      const result = formManager.processForm(
        this.models,
        false,
        this.generic ? null : this.id,
      );
      if (this.alias === "document") {
        result["trainingCourses"] = [
          { id: parseInt(this.$route.params.trainingCourseId) },
        ];
        result["uploader"] = { id: this.user.id };
      } else {
        result["trainingCourse"] = { id: this.$route.params.trainingCourseId };
      }
      this.loading = true;
      let urlCall =
        "/app/studea-manager/" +
        this.$route.params.trainingCourseId +
        "/" +
        (this.generic && this.postGeneric ? "generic/" : "") +
        encodeURI(this.alias) +
        (this.generic ? "/post" : "/");
      if (this.id) {
        urlCall =
          "/app/studea-manager/" +
          this.$route.params.trainingCourseId +
          "/" +
          (this.generic && this.putGeneric ? "generic/" : "") +
          encodeURI(this.alias) +
          (this.generic ? "/post" : "") +
          ("/" + this.id);
      }
      (this.id
        ? apiConnection.put(urlCall, result)
        : apiConnection.post(urlCall, result)
      ).then((data) => {
        const message = this.$tc(
          "global." + (this.edit ? "edit" : "add") + ".success",
          this.gender,
          {
            name: this.$t("studea_manager." + this.tradPrefix + ".title", 1),
          },
        );
        const route = {
          name: this.backPath,
          params: { entity: this.alias },
        };
        formManager.processResponse(data, route, message, this.models);
        this.componentKey++;
        this.loading = false;
      });
    },

    cancel() {
      this.$router.push({
        name: this.backPath,
        params: { entity: this.alias },
      });
    },

    getCurrentMenuEntry() {
      let current = null;
      Object.values(this.menu).forEach((item) => {
        if (!current) {
          current = Object.values(item.children).find(
            (item) => item.params && item.params.alias === this.alias,
          );
        }
      });
      this.current = current;
    },

    init() {
      this.loading = true;
      this.generic = !this.$route.meta.notGeneric;
      this.postGeneric = !this.$route.meta.postNotGeneric;
      this.putGeneric = !this.$route.meta.putNotGeneric;
      this.edit = this.id || this.$route.path.includes("edit");
      this.alias = this.$router.currentRoute.value.params.alias;
      this.tradPrefix = stringManager.toCamelCase(this.alias);
      this.id = this.$router.currentRoute.value.params.id;
      this.unique =
        this.current && this.current.params
          ? this.current.params.unique
          : false;
      this.gender = stringManager.gender(this.alias);
      this.title = this.$tc(
        "global." + (this.edit ? "edit" : "add") + ".title",
        this.gender,
        {
          name: this.$t(
            "studea_manager." + this.tradPrefix + ".title",
            1,
          ).toLowerCase(),
        },
      );

      this.backPath = this.$route.query.backPath
        ? this.$route.query.backPath
        : this.$route.meta.backPath;

      if (this.$route.query.set) {
        this.applyQuery();
      }

      studeaManagerManager
        .form(
          this.alias,
          this.$route.params.trainingCourseId,
          this.id,
          this.params,
          this.generic,
          false,
          this.unique,
        )
        .then((data) => {
          this.models = data;

          if (this.models.skillSession) {
            const currentSession = this.models.skillSession.vars.choices.find(
              (data) =>
                data.data.id ===
                Number(this.$router.currentRoute.value.query.currentSession),
            );
            if (currentSession) {
              this.models.skillSession.vars.value = currentSession.data.id;
            }
          }
          this.componentKey++;
          this.loading = false;
        });
    },

    applyQuery() {
      const set = JSON.parse(this.$route.query.set);
      this.params["options"] = [];
      if (set && Object.keys(set).length) {
        let i = 0;
        Object.values(set).forEach((query) => {
          if (i > 0) {
            this.params.options = this.params.options.concat("&");
          }
          this.params.options = this.params.options.concat(
            query.name + "=" + query.value,
          );
          i++;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-content {
  padding: 20px;
}
</style>
