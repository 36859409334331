import { apiConnection } from "@/services/api-connection";
import { trainingCourseStore } from "@/main";

const URL_EVENT_LIST = "/app/event/list/";

class AbsenceManager {
  loadEventList = (id, params) => {
    return apiConnection
      .get(URL_EVENT_LIST + id, params)
      .then((response) => response);
  };

  justifyForm = (id, type) => {
    return apiConnection
      .get(
        "/app/" +
          trainingCourseStore.trainingCourse.id +
          "/absence/justify/form/" +
          type +
          "/" +
          id,
      )
      .then((response) => response);
  };

  putJustifyForm = (id, params) => {
    return apiConnection
      .put(
        "/app/" +
          trainingCourseStore.trainingCourse.id +
          "/absence/justify/put/" +
          id,
        params,
      )
      .then((response) => response);
  };
}

export const absenceManager = new AbsenceManager();
